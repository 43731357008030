import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import { withRouter } from 'next/router'

import Home from '@/components/Home'
import { getReserveRestaurants } from '@/actions/restaurants'
import { getCards } from '@/actions/cards'
import { getNewBlogs } from '@/actions/blogs'
import {
  getWeeklyHotRestaurants,
  getRecentUserCheckin,
  getRecentPost,
  getCategoryBlockList,
} from '@/actions/home'

import { CITY } from '@/staticData/cityMapping'
import ABTesting from '@/libs/ABTesting'

export const RESTAURANT_LIMIT = 3
export const CARD_LIMIT = 4

async function getInitialPropsBackend({ query, store }) {
  await store.dispatch(
    getReserveRestaurants({
      city: CITY.taipei.id,
      limit: RESTAURANT_LIMIT,
      offset: 0,
    })
  )
  await store.dispatch(
    getCards({
      listType: 'promote',
      limit: CARD_LIMIT,
      offset: 0,
    })
  )
  await store.dispatch(getNewBlogs())
  await store.dispatch(getWeeklyHotRestaurants())
  await store.dispatch(getRecentPost())
  await store.dispatch(getRecentUserCheckin())
  await store.dispatch(getCategoryBlockList())

  const showAppDownloadOnMount = !!(query.app === 'true')
  const dotParam = query.dot
  return { showAppDownloadOnMount, dotParam }
}

async function getInitialPropsFronetnd({ query, store }) {
  store.dispatch(
    getReserveRestaurants({
      city: CITY.taipei.id,
      limit: RESTAURANT_LIMIT,
      offset: 0,
    })
  )
  store.dispatch(
    getCards({
      listType: 'promote',
      limit: CARD_LIMIT,
      offset: 0,
    })
  )
  store.dispatch(getNewBlogs())
  store.dispatch(getWeeklyHotRestaurants())
  store.dispatch(getRecentPost())
  store.dispatch(getRecentUserCheckin())
  store.dispatch(getCategoryBlockList())
  const showAppDownloadOnMount = !!(query.app === 'true')
  return { showAppDownloadOnMount }
}

class HomeContainer extends Component {
  static async getInitialProps({ res, query, store }) {
    if (res) {
      return await getInitialPropsBackend({ res, query, store })
    } else {
      return await getInitialPropsFronetnd({ res, query, store })
    }
  }

  render() {
    const { cards, restaurants, home, showAppDownloadOnMount, dotParam } = this.props
    return (
      <Home
        newBlogs={home.get('newBlogs')}
        weeklyHotRestaurants={home.get('weeklyHot')}
        recentPosts={home.get('recentPosts')}
        reservationRestaurants={restaurants.getIn(['reservation', 'data'])}
        promoteCards={cards.getIn(['promote', 'data'])}
        userCheckinItems={home.get('checkinList')}
        categoryBlockList={home.get('categoryBlockList')}
        showAppDownloadOnMount={showAppDownloadOnMount}
        dotParam={dotParam}
      />
    )
  }
}

HomeContainer.propTypes = {
  home: PropTypes.instanceOf(Map).isRequired,
  restaurants: PropTypes.instanceOf(Map).isRequired,
  cards: PropTypes.instanceOf(Map).isRequired,
  getReserveRestaurants: PropTypes.func.isRequired,
  getCards: PropTypes.func.isRequired,
  getWeeklyHotRestaurants: PropTypes.func.isRequired,
  getRecentUserCheckin: PropTypes.func.isRequired,
  showAppDownloadOnMount: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => {
  return {
    restaurants: state.restaurants,
    cards: state.cards,
    home: state.home,
  }
}

export  { HomeContainer }
export default connect(mapStateToProps, {
  getReserveRestaurants,
  getCards,
  getWeeklyHotRestaurants,
  getRecentUserCheckin
})(withRouter(HomeContainer))
