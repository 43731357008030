import css from 'styled-jsx/css'

export default css`
.checkin-outer {
  margin-top: 10px;
  padding-top: 10px;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(8px);
  border-radius: 8px;
  padding-bottom: 15px;
  box-shadow: 0 1px 2px #ddd;
  .checkin-item-outer {
    width: 96%;
    padding-left: 4.8%;
    margin-left: auto;
    margin-right: auto;
    @media(max-width: 500px) {
      padding-left: 0;
    }
    .checkin-item {
      background-size: cover;
      background-position: center;
      background-color: transparent;
      float: left;
      margin-right: 2.1%;
      margin-bottom: 15px;
      width: calc(33.33% - 3.2%);
      position: relative;
      @media(max-width: 500px) {
        width: 100%;
        margin-right: 0;
        margin-bottom: 30px;
      }
      &:last-child {
        margin-right: 0%;
      }
      .info {
        color: #fff;
        text-align: left;
        padding: 10px 12px;
        border-radius: 5px;
        background: #e9e9e9;
        width: 100%;
        height: 60%;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        margin-top: 8px;
        .checkin-img {
          border-radius: 5px;
          width: 100%;
          padding-bottom: 62%;
          background-size: cover;
          background-position: center;
        }
        .message {
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          padding-left: 6px;
          padding-right: 6px;
          max-height: 4.1em;
          margin-top: 12px;
          margin-bottom: 10px;
          overflow: hidden;
          text-align: left;
          color: #555;
          width: 100%;
          @media(max-width: 500px) {
            display: block;
          }
          @media(max-width: 320px) {
            font-size: 12px;
          }
        }
      }
    }
    .info:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 40px;
      width: 0;
      height: 0;
      border: 20px solid transparent;
      border-top-color: #e9e9e9;
      border-bottom: 0;
      border-left: 0;
      margin-left: -10px;
      margin-bottom: -12px;
    }
    .profile-container {
      display: flex;
      flex-direction: row;
      justify-content:center;
      align-items: center;
      margin-top: 16px;
      padding: 0 12px;
    }
    .username-container {
      display: flex;
      flex-direction: column;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
      padding-left: 12px;
      overflow: hidden;
      text-align: left;
      .username {
        font-size: 16px;
        padding: 6px 0;
        color: #0b5d90;
      }
      .checkin-date {
        font-size: 14px;
        color: silver;
      }
      @media(max-width: 657px) {
        font-size: 16px;
      }
      @media(max-width: 500px) {
        font-weight: bold;
      }
      @media(max-width: 320px) {
        font-size: 13px;
      }
    }
    .profile-img {
      background-position: center;
      background-size: cover;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      border: 1px solid #eee;
      box-sizing: inherit;
    }
  }
  .title {
    font-size: 36px;
    color: #0b5d90;
    margin-top: 36px;
    margin-bottom: 4px;
    font-weight: 500;
  }
  .subtitle {
    font-size: 18px;
    color: #0b5d90;
    padding-bottom: 32px;
  }
  .btn-outer {
    clear: both;
  }
  :global(.btn.explore-more) {
    margin-top: 12px;
    color: white;
    background-color: #0b5d90;
    font-size: 16px;
    width: 300px;
    height: 50px;
    &:hover {
      background-color: #0b5d90;
    }
  }
}
`
