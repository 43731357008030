import React, { PureComponent } from 'react'
import styles from '@/styles/Home/TopBlogger.module.js'
import Button from '@mui/material/Button'
import GAEventTracker from '@/libs/GAEventTracker'

export const BLOG_LINKS = [
  'https://ifoodie.tw/user/54b31693d4fdab26d1baf162/iframe?style=responsive',
  'https://ifoodie.tw/user/5364740040d18d2397752d8a/iframe?style=responsive',
  'https://ifoodie.tw/user/53e0cbedd4fdab6c0a2b553d/iframe?style=responsive',
]

class TopBlogger extends PureComponent {
  render() {
    return (
      <div className='top-blogger-outer'>
        <style jsx>{styles}</style>
        <h2 className='title'>
          優質餐廳 x 優質部落客
        </h2>
        <div className='subtitle'>
          幫助餐廳尋找優質部落客
        </div>
        <div className='top-blogger'>
          <div className='top-blogger-item'>
            <iframe
              className='lazyload'
              frameBorder="0" height="425" scrolling="no"
              src={BLOG_LINKS[0]} />
          </div>
          <div className='top-blogger-item'>
            <iframe
              className='lazyload'
              frameBorder="0" height="425" scrolling="no"
              src={BLOG_LINKS[1]} />
          </div>
          <div className='top-blogger-item'>
            <iframe
              className='lazyload'
              frameBorder="0" height="425" scrolling="no"
              src={BLOG_LINKS[2]} />
          </div>
        </div>
        <div className='btn-outer'>
          <Button
            target='_blank'
            onClick={this._sendGAEvent}
            href='/why-ifoodie'
            className='btn btn-advertise'>
            店家行銷方案
          </Button>
        </div>
      </div>
    )
  }
  _sendGAEvent() {
    GAEventTracker.sendEvent({
      action: '點擊店家行銷方案',
      label: '按鈕',
      category: '首頁區塊點擊'
    })
  }
}
export default TopBlogger
