import css from 'styled-jsx/css'

export default css`
  .articleItem {
    display: block;
    padding: 16px 0px;
    border-bottom: solid 1px #e6e6e6;
    position: relative;
    @media (min-width: 800px) {
      padding: 12px;
      margin: 0 40px;
      &.restaurant,
      &.post {
        margin: 0;
      }
      &.recommend-article {
        margin: 0;
      }
    }
    &:last-child {
      border: none;
    }
    .click-tracker {
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      &.mobile {
        display: none;
        @media (max-width: 800px) {
          display: block;
        }
      }
    }
  }

  .mobileItem {
    display: block;
    @media (min-width: 800px) {
      display: none;
    }
  }

  .desktopItem {
    display: none;
    @media (min-width: 800px) {
      display: flex;
    }
    .desktopInfo {
      width: 100%;
      padding-left: 12px;
      position: relative;
    }
    .largeCoverInfo {
      margin-bottom: 6px;
    }
    &:hover {
      .articleTitle {
        color: #0b5d90;
      }
      .desktopCover {
        width: 350px;
        height: 204px;
        margin-left: -12px;
        margin-top: -10px;
      }
    }
  }

  .desktopCoverWraper {
    width: 326px;
    height: 190px;
    overflow: hidden;
    flex: none;
  }

  .desktopCover {
    width: 326px;
    height: 190px;
    object-fit: cover;
    background-color: #eee;
    transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  }

  .articleItem.large .desktopItem {
    @media (min-width: 800px) {
      display: flex;
    }
    .desktopInfo {
      width: 100%;
      padding-left: 12px;
      position: relative;
    }
    .largeCoverInfo {
      margin-bottom: 6px;
    }
    .desktopCoverWraper {
      width: 535px;
      height: 309px;
    }
    .desktopCover {
      width: 535px;
      height: 309px;
    }
    .articleTitle {
      font-size: 26px;
      line-height: 37px;
      max-height: 114px;
    }
    &:hover {
      .desktopCover {
        width: 551px;
        height: 329px;
        margin-left: -8px;
        margin-top: -10px;
      }
    }
    @media (max-width: 1200px) {
      .desktopCoverWraper {
        width: 489px;
        height: 277px;
      }
      .desktopCover {
        width: 489px;
        height: 277px;
      }
      .articleTitle {
        font-size: 24px;
        line-height: 31px;
        max-height: 96px;
      }
      &:hover {
        .desktopCover {
          width: 505px;
          height: 297px;
          margin-left: -8px;
          margin-top: -10px;
        }
      }
    }
    @media (max-width: 1024px) {
      .desktopCoverWraper {
        width: 420px;
        height: 240px;
      }
      .desktopCover {
        width: 420px;
        height: 240px;
      }
      &:hover {
        .desktopCover {
          width: 436px;
          height: 260px;
          margin-left: -8px;
          margin-top: -10px;
        }
      }
    }
    @media (max-width: 900px) {
      .desktopCoverWraper {
        width: 360px;
        height: 200px;
      }
      .desktopCover {
        width: 360px;
        height: 200px;
      }
      .articleTitle {
        font-size: 22px;
        line-height: 26px;
        max-height: 80px;
      }
      &:hover {
        .desktopCover {
          width: 376px;
          height: 220px;
          margin-left: -8px;
          margin-top: -10px;
        }
      }
    }
  }

  .avatar {
    width: 20px;
    height: 20px;
    border-radius: 10px;
    margin-right: 6px;
    flex: none;
    background-color: #eee;
    object-fit: cover;
  }
  .pinned {
    margin-left: 10px;
    color: white;
    background-color: #1c66c1;
    border-radius: 5px;
    padding: 2px 6px;
    font-size: 12px;
    line-height: 17px;
    :global(.icon) {
      width: 12px;
      height: 12px;
      vertical-align: middle;
      margin-top: -1px;
    }
  }
  .datetime {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.35);
    margin-left: 10px;
    display: none;
    @media (min-width: 800px) {
      display: inline-block;
    }
  }
  .categoryCity {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.65);
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    :global(.icon-place) {
      color: rgba(0, 0, 0, 0.45);
      width: 13px;
      height: 13px;
      margin-right: 2px;
      margin-left: 10px;
      margin-bottom: 1px;
      vertical-align: middle;
    }
    .category {
      font-weight: 600;
    }
    .city {
      font-size: 13px;
      color: rgba(0, 0, 0, 0.45);
      font-weight: 500;
    }
  }
  .articleInfo {
    display: flex;
    align-items: initial;
  }
  .textInfo {
    flex: 1;
  }

  .smallCoverInfo {
    .postType {
      color: gray;
      font-weight: bold;
    }
    .articleDatetime {
      color: #9a9a9a;
    }
    .info {
      display: inline-block;
      margin-right: 8px;
    }
  }

  .coverInfo {
    color: gray;
    margin-top: 8px;
    margin-bottom: 2px;
    font-size: 12px;
    line-height: 14px;
    .featured {
      color: #1b78c1;
    }
  }

  .articleTitle {
    font-size: 17px;
    font-weight: 600;
    color: #272727;
    margin-bottom: 4px;
    line-height: 24px;
    transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    text-decoration: none;
    .articleCity {
      font-size: 12px;
      line-height: 14px;
      vertical-align: middle;
      padding: 0 6px;
      display: none;
      color: gray;
      border-left: 1px solid gray;
      border-right: 1px solid gray;
    }
    .text {
      margin-right: 12px;
      display: inline;
      vertical-align: middle;
    }
    @media (min-width: 800px) {
      margin-bottom: 12px;
      font-size: 20px;
      line-height: 29px;
      max-height: 60px;
      overflow: hidden;
    }
  }

  .articleContent {
    margin-top: 4px;
    font-size: 15px;
    color: #646464;
    flex: 1;
    position: relative;
    min-height: 46px;
    line-height: 22px;
    margin-bottom: 8px;
    @media (min-width: 800px) {
      font-size: 15px;
    }
  }

  .articleContentInner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .articleStats {
    font-size: 13px;
    color: #646464;
    flex: none;
    display: flex;
    align-items: center;
    .authorName {
      // max-width: 108px;
      margin-right: 6px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .stats {
      flex: 1;
      display: flex;
      &.hide {
        display: none;
      }
    }
    .popularity {
      display: inline-block;
      margin-left: auto;
      :global(.icon) {
        width: 16px;
        height: 16px;
        margin-right: 3px;
        vertical-align: middle;
      }
      :global(.icon.yellow) {
        color: #fdb602;
      }
      :global(.icon.red) {
        color: #e00000;
      }
      .text {
        vertical-align: middle;
      }
    }
    @media (min-width: 800px) {
      font-size: 14px;
      font-weight: 500;
      position: absolute;
      bottom: 0;
      width: 100%;
    }
    span {
      margin-right: 16px;
    }
  }
  .likeButton {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 16px;
    :global(.likeIcon) {
      width: 15px;
      height: 15px;
      flex: none;
      color: #d23a3ae3;
    }
    .likeCount {
      margin-left: 6px;
    }
  }

  .replyStat {
    display: none;
    :global(.replyIcon) {
      vertical-align: middle;
      width: 16px;
      height: 16px;
    }
    .replyCnt {
      margin-left: 6px;
    }
  }

  .coverWraper.mobile {
    margin-bottom: 12px;
    width: 100%;
    padding-top: 55%;
    position: relative;
    @media (min-width: 800px) {
      display: none;
    }
  }

  .cover {
    flex: none;
    width: 80px;
    height: 80px;
    border-radius: 4px;
    margin-left: 5px;
    margin-top: 2px;
    object-fit: cover;
    @media (min-width: 800px) {
      margin-left: 10px;
    }
  }

  .articleCover {
    display: none;
    width: 100%;
    height: 200px;
    flex: none;
    object-fit: cover;
    background-color: #eee;
  }

  .largeCoverInfo {
    display: block;
    .info {
      display: inline-block;
      margin-right: 8px;
    }
  }

  @media (max-width: 800px) {
    .desktopCover {
      display: none;
    }
    .largeCover {
      .smallCoverInfo {
        display: none;
      }
      .articleTitle {
        margin-top: 2px;
        margin-bottom: 10px;
      }
      .cover {
        display: none;
      }
      .articleContent {
        display: none;
      }
      .articleCover {
        display: block;
      }
    }
  }
`
