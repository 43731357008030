export default function formatNumber(
  number,
  { digit = 0, detectSmallNumber = false } = {}
) {
  if (number == null) {
    return null
  }
  let maximumFractionDigits = digit
  if (detectSmallNumber) {
    if (Math.abs(number) < 1) {
      maximumFractionDigits = 6
    } else if (Math.abs(number) < 10) {
      maximumFractionDigits = 5
    }
  }
  return number.toLocaleString('en-US', {
    maximumFractionDigits,
  })
}
