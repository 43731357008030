
import css from 'styled-jsx/css'

export default css`
.category-block-outer {
  padding-top: 20px;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(8px);
  border-radius: 8px;
  padding-bottom: 15px;
  box-shadow: 0 1px 2px #ddd;
  .category-block-item-outer {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    .clearBoth {
      clear: both;
    }
    .img-provider {
      text-align: right;
      padding-right: 10px;
      color: #999;
      font-size: 15px;
      a {
        color: #5b5b5b;
        font-weight: 600;
      }
    }
    @media (max-width: 1000px) {
      width: 100%;
    }
    .mobile-container {
      overflow-x: auto;
      padding-bottom: 16px;
      .category-block-item {
        width: 150px;
        height: 150px;
        margin-right: 8px;
        border-radius: 6px;
        box-shadow: rgba(22, 34, 51, 0.2) 0px 6px 16px;
        @media(min-width: 640px) {
          width: 220px;
          height: 190px;
          margin-right: 12px;
          border-radius: 4px;
        }
        .mask {
          top: 0;
          background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3) 40%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0) 100%);
          opacity: 1;
          width: 100%;
          height: 100%;
          display: block;
          position: absolute;
        }
        .info {
          width: 100%;
          color: #fff;
          position: absolute;
          top: 0;
          left: 0;
          padding: 12px;
          transform: translate(0, 0);
          .name {
            font-size: 17px;
            text-align: left;
            padding-bottom: 6px;
            font-weight: bold;
          }
          .count {
            font-size: 14px;
            text-align: left;
          }
        }
      }
    }
    .category-block-item {
      text-align: center;
      box-sizing: border-box;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: calc(33.33% - 2.1%);
      height: 250px;
      margin-right: 2.1%;
      margin-bottom: 1.4%;
      display: inline-block;
      position: relative;
      border-radius: 3px;
      overflow: hidden;
      box-shadow: rgba(22, 34, 51, 0.2) 0px 6px 16px;
      &.restaurant {
        height: 200px;
        @media(max-width: 500px) {
          height: 130px;
        }
      }
      .category-block-item-inner {
        position: relative;
        width: 100%;
        height: 100%;
      }
      @media(max-width: 500px) {
        width: 100%;
        height: 200px;
      }
      &:hover .mask{
        opacity: 1;
        transition-duration: .2s;
        transition-property: opacity;
      }
      &.double-size {
        width: calc(66.66% - 2.1%);
        @media(max-width: 500px) {
          width: 100%;
        }
      }
      &.margin-zero {
        margin-right: 0;
      }
      .info {
        width: 100%;
        color: #fff;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        .name {
          font-size: 36px;
          padding-bottom: 6px;
        }
        .count {
          font-size: 18px;
        }
      }
      .mask {
        top: 0;
        background-color: rgba(0,0,0,0.6);
        opacity: .1;
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
      }
      .cover {
        object-fit: cover;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        display: block;
        /* position: absolute; */
        background-size: cover;
      }
    }
  }
  .title {
    font-size: 36px;
    color: #0b5d90;
    margin-top: 56px;
    margin-bottom: 4px;
    font-weight: 500;
  }
  .subtitle {
    font-size: 18px;
    color: #0b5d90;
    padding-bottom: 32px;
  }

  &.page {
    padding-top: 0px;
    background-color: white;
    border-radius: 8px;
    padding-bottom: 15px;
    box-shadow: none;
    .category-block-item-outer {
      width: 100%;
      padding: 0 4px;
      @media(max-width: 640px) {
        padding: 0 4px;
      }
    }
    .title {
      font-weight: 700;
      font-size: 20px;
      color: #4a4a4a;
      margin-bottom: 1.2em;
      margin-top: 32px;
      @media(max-width: 640px) {
        margin-top: 40px;
        /* margin-top: 17px; */
        font-size: 21px;
        /* margin-bottom: 6px; */
      }
    }
  }
}
`