import React, { Component } from 'react'
import { withRouter } from 'next/router'
import Footer from '@/components/Footer'

class FooterContainer extends Component {
  render() {
    return (
      <Footer />
    )
  }
}

export  { FooterContainer }
export default withRouter(FooterContainer)
