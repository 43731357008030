import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { Map } from 'immutable'

import AppFrame from '@/components/AppFrame'
import UnauthedHeader from '@/components/header/UnauthedHeader'
import AdExchange from '@/components/AdExchange'
import Search from '@/components/Home/Search'
import BlogList from '@/components/Home/BlogList'
import WeeklyHot from '@/components/Home/WeeklyHot'
import UserCheckin from '@/components/Home/UserCheckin'
import RecentPost from '@/components/Home/RecentPost'
import CategoryBlock from '@/components/Home/CategoryBlock'
import AppDownload from '@/components/Home/AppDownload'
import TopBlogger from '@/components/Home/TopBlogger'
import BottomTabbar, { TabBar } from '@/components/BottomTabbar'
// import HaloBackground from '@/components/utils/threejs/HaloBackground'

import styles from '@/styles/Home/index.module.js'

import GAEventTracker from '@/libs/GAEventTracker'
import withMobileStatus from '@/hocs/withMobileStatus'

const ENABLE_970x250 = false
const ADX_PROP = {
  client: 'div-gpt-ad-1604728124879-0',
  slot: '/230404055/ca-pub-3284479043848404-tag/ifoodie-AdX-Home-970x250',
  sizes: [[970, 250]],
}

class Home extends PureComponent {
  constructor(props) {
    super(props)
    this._setRefAppDownload = this._setRefAppDownload.bind(this)
    this._handleEventClick = this._handleEventClick.bind(this)
    this._getExploreRoute = this._getExploreRoute.bind(this)
  }
  componentDidMount() {
    const { showAppDownloadOnMount } = this.props
    if (typeof this.refAppDownload !== 'undefined' && showAppDownloadOnMount) {
      this.refAppDownload.scrollIntoView()
    }
  }
  render() {
    const { mobileStatus } = this.props
    const isMobileOs = mobileStatus.get('isMobile')
    const {
      newBlogs,
      weeklyHotRestaurants,
      reservationRestaurants,
      promoteCards,
      userCheckinItems,
      dotParam,
      recentPosts,
      categoryBlockList,
    } = this.props
    return (
      <AppFrame renderHeader={this._renderHeader}>
        <style jsx>{styles}</style>
        {/* <CrispChat /> */}
        <div className="home-outer" id='homeOuter'>
          <Search getExploreRoute={this._getExploreRoute} isMobileOs={isMobileOs} />
          <div className="container">
            {!isMobileOs && ENABLE_970x250 && (
              <div className="adx-970x250-container">
                <AdExchange
                  client={ADX_PROP.client}
                  slot={ADX_PROP.slot}
                  sizes={ADX_PROP.sizes}
                />
              </div>
            )}
            <CategoryBlock
              getExploreRoute={this._getExploreRoute}
              categoryBlockList={categoryBlockList}
            />
            {this._renderCampaign()}
            <RecentPost recentPosts={recentPosts} />
            {/* <Promote cards={promoteCards} /> */}
            {/* <Reservation restaurants={reservationRestaurants} /> */}
            {!isMobileOs && (
              <UserCheckin
                userCheckinItems={userCheckinItems}
                dotParam={dotParam}
                isMobileOs={isMobileOs}
              />
            )}
            <WeeklyHot restaurants={weeklyHotRestaurants} />
            <BlogList blogs={newBlogs} />
          </div>
          <div ref={this._setRefAppDownload} className="app-download-section">
            <AppDownload />
          </div>
          <div className="container">
            <TopBlogger />
          </div>
          <div id='homeBg' style={{position: 'fixed', width: '100%', height: '100%', top: 0, zIndex: -1, backgroundColor: '#f5f3f3'}} />
        </div>
        {/* <BirdBackground domId='homeBg' /> */}
        {/* <TopologyBackground domId='homeBg' theme='bright' /> */}
        {/* <HaloBackground domId='homeBg' /> */}
        {isMobileOs && (
          <BottomTabbar
            initTab={TabBar.TABBAR_HOME}
            isMobileOs={isMobileOs}
          />
        )}
      </AppFrame>
    )
  }
  _getExploreRoute(keyword) {
    return `/explore/台北市/list/${keyword}`
  }
  _renderCampaign = () =>  {
    const { mobileStatus } = this.props
    const isMobileOs = mobileStatus.get('isMobile')
    return (
      <div className="campaign">
        <style jsx>{styles}</style>
        <h2 className="title">最新功能</h2>
        <div className="subtitle">愛食記『部落客媒合』專區</div>
        <a href="/case/list" target="_blank">
          {isMobileOs ? (
            <div className="mobile-bg">
              <img
                src="https://lh3.googleusercontent.com/4ybXLFmOvTReRgzKd_FkOGQnkwPYc4agpV9KkDQM_4G3hZ9vArHT5xXfvL-w-_16u8ne7YA85XRUOme3ymPTAZvF2ViLrCyTE3EMEvdKCvX3EQ=s720"
                style={{ width: '100%', borderRadius: 6 }}
              />
            </div>
          ) : (
            <div>
              <img
                style={{ width: '100%', borderRadius: 6 }}
                src="https://lh3.googleusercontent.com/nNV6JeDODDAMTWkp4k_XgRg8mopl7kSl59VwJn-tTNhz557jxPn3cXEUdiDEyZSrjKKr-K0JMhTxII86Q7yZ0mbuB2ragp-1syFFOxUvhIvq=s1200"
              />
            </div>
          )}
        </a>
      </div>
    )
  }
  _renderHeader() {
    return (
      <UnauthedHeader
        renderCenter={() => null}
        isVisiableCityAndCategoryMenu={true}
        classes={{ root: 'home-page-header' }}
      />
    )
  }
  _setRefAppDownload(el) {
    this.refAppDownload = el
  }
  _handleEventClick() {
    GAEventTracker.sendEvent({
      action: '點擊活動Event',
      category: '首頁活動',
    })
  }
}

Home.propTypes = {
  newBlogs: PropTypes.instanceOf(List).isRequired,
  weeklyHotRestaurants: PropTypes.instanceOf(List).isRequired,
  reservationRestaurants: PropTypes.instanceOf(List).isRequired,
  promoteCards: PropTypes.instanceOf(List).isRequired,
  userCheckinItems: PropTypes.instanceOf(List).isRequired,
  recentPosts: PropTypes.instanceOf(List).isRequired,
  categoryBlockList: PropTypes.instanceOf(List).isRequired,
  showAppDownloadOnMount: PropTypes.bool.isRequired,
  mobileStatus: PropTypes.instanceOf(Map).isRequired,
}

export default withMobileStatus(Home)
