import css from 'styled-jsx/css'

export default css`
  .adsbygoogle {
    display: block;
    min-width: 1px;
    min-height: 1px;
    &.fluid-ad {
      min-height: 300px;
      border-bottom: 1px solid #e6e6e6;
    }
  }

  .fbAdLoaded {
    padding: 0 6px;
  }

  .thirdPartyRoot {
    position: relative;
    background: white;
    padding-bottom: 7px;
    border-bottom: 1px solid #d2d2d2;
    margin-top: 2px;
    .sponsored {
      display: none;
      text-align: left;
      margin: 7px 5px;
    }
  }

  .thirdPartyMediaClass {
    height: 190px;
    width: 100%;
    max-width: 380px;
    margin-right: 10px;
    :global(img) {
      object-fit: cover;
    }
  }

  .thirdPartyTitleClass {
    text-align: left;
    font-weight: 600;
    font-size: 18px;
    margin: 8px 0 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 20px;
    line-height: 20px;
    color: #000000ba;
    padding: 0 6px;
    white-space: nowrap;
  }

  .thirdPartySubtitleClass {
    color: #000000ba;
    display: none;
  }

  .thirdPartyBodyClass {
    height: 21px;
    overflow: hidden;
    color: #5a5a5a;
    line-height: 16px;
    padding: 0 11px;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .thirdPartyCallToActionClass {
    display: inline-block;
    font-family: sans-serif;
    font-weight: 600;
    margin-top: 4px;
    padding: 5px;
    border-radius: 5px;
    width: 100%;
    background: white;
    color: rgb(255, 102, 0);
    border: 1px solid rgb(255, 102, 0);
  }
`
