import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { motion as m } from 'framer-motion';
import { List } from 'immutable'

import { varFade, MotionViewport } from '@/libs/components/animate';
import ArticleItem from '@/components/Community/ArticleItem'

import Container from '@mui/material/Container'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import Button from '@mui/material/Button'

import GAEventTracker from '@/libs/GAEventTracker'
import styles from '@/styles/Home/RecentPost.module.js'

class RecentPost extends PureComponent {
  render() {
    const { recentPosts, customClass } = this.props
    return (
      <Container
        component={MotionViewport}
        disableAnimatedMobile={false}
      >
      <div className={`recent-post-outer ${customClass}`}>
        <style jsx>{styles}</style>
        <h2 className="title" style={{ display: 'none' }}>
          <a href="/c/list/all">最新美食文章</a>
        </h2>
        <div className="subtitle" style={{ display: 'none' }}>
          熱門流行美食資訊
        </div>
        <div className="checkin-item-outer">
          {recentPosts.take(3).map((recentPostItem) => {
            return (
              <m.div variants={varFade().inUp} key={recentPostItem.get('postId')}>
              <ArticleItem
                article={recentPostItem}
                onClickArticle={this._onClickArticle}
                linkTarget="_blank"
                customClass={customClass}
                getIsLike={this._getIsLike}
                getLikeCount={this._getLikeCount}
              />
              </m.div>
            )
          })}
          <div className="post-link-outer">
            <div className="post-link-title">推薦專欄文章：</div>
            {recentPosts.slice(3, 7).map((recentPostItem) => {
              return (
                <a
                  className="post-link"
                  target="_blank"
                  href={`/c/list/p/${recentPostItem.get('postId')}`}
                  key={recentPostItem.get('postId')}>
                  • {recentPostItem.get('title')}
                </a>
              )
            })}
          </div>
        </div>
        <div className="btn-outer">
          <Button
            onClick={this._sendGAEvent}
            target="_blank"
            href="/c/list/all"
            className="btn">
            開啟美食專欄
            <ArrowForwardIosIcon className="icon-arrow" />
          </Button>
        </div>
      </div>
      </Container>
    )
  }
  _getCanonicalUrl(postInfo) {
    return `/c/list/p/${postInfo.get('postId')}`
  }
  _onClickArticle() {}
  _getIsLike() {
    return false
  }
  _getLikeCount = (article) => {
    return article.get('likeCnt')
  }
  _sendGAEvent() {
    GAEventTracker.sendEvent({
      action: '點擊使用者動態',
      label: '按鈕',
      category: '首頁區塊點擊',
    })
  }
}

RecentPost.propTypes = {
  recentPosts: PropTypes.instanceOf(List).isRequired,
  customClass: PropTypes.string,
}

RecentPost.defaultProps = {
  customClass: '',
}

export default RecentPost
