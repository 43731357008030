export const CITY = {
  taipei: {
    id: 1,
    name: 'taipei',
    display: '台北',
  },
  kaohsiung: {
    id: 2,
    name: 'kaohsiung',
    display: '高雄',
  },
  tainan: {
    id: 3,
    name: 'tainan',
    display: '台南',
  },
  taichung: {
    id: 4,
    name: 'taichung',
    display: '台中',
  },
  taoyuan: {
    id: 5,
    name: 'taoyuan',
    display: '桃園',
  },
  hsinchu: {
    id: 7,
    name: 'hsinchu',
    display: '新竹',
  },
}