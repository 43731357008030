import css from 'styled-jsx/css'

export default css`
  #homeBg {
    :global(canvas) {
      /* opacity: 0.3; */
    }
  }
  :global(.app-bar.home-page-header) {
    background-color: transparent;
    box-shadow: none;
    position: absolute;
    :global(.navigation-list .navigation-divider) {
      border: 0;
    }
    :global(.tool-bar) {
      :global(.right) {
        :global(.sub-header) {
          :global(.app-bar) {
            :global(.tool-bar-root) {
              :global(.sub-header-list) {
                :global(.sub-header-divider) {
                  border: 0;
                }
                :global(.sub-header-item) {
                  :global(.sub-header-btn) {
                    padding: 12px;
                    margin: 0;
                    &:hover {
                      margin-top: 0;
                    }
                  }
                  :global(.menu-content-outer) {
                    padding-top: 0;
                  }
                }
              }
              :global(.sub-header-list-right) {
                display: none;
              }
            }
          }
        }
      }
    }
    :global(.menu-content) {
      :global(.options-outer) {
        :global(ul) {
          :global(li) {
            :global(a) {
              background-color: transparent;
            }
          }
        }
      }
    }
  }
  .home-outer {
    .container {
      max-width: 68em;
      min-height: 600px;
      padding: 20px 60px;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      .campaign {
        margin-bottom: 6px;
        .mobile-bg {
        }
        .title {
          margin-bottom: 4px;
          font-size: 36px;
          color: #0b5d90;
          margin-top: 56px;
          font-weight: 500;
        }
        .subtitle {
          font-size: 18px;
          color: #0b5d90;
          padding-bottom: 24px;
        }
        img {
          border: 1px solid #fafafa;
        }
      }
    }
    .app-download-section {
      padding-top: 90px;
      padding-bottom: 10px;
      margin-top: 20px;
      /* background-color: rgba(255, 255, 255, 0.3); */
      background: linear-gradient(
        135deg,
        rgba(255, 255, 255, 0.2) 0,
        rgba(11, 93, 144, 0.2) 70%,
        rgba(255, 255, 255, 0.1) 100%,
      );
      backdrop-filter: blur(8px);
      border-radius: 8px;
      box-shadow: 0 1px 2px #ddd;
    }
    .adx-970x250-container {
      min-height: 250px;
      border: 1px solid #efefef;
      margin-top: 12px;
      margin-bottom: -22px;
      overflow: hidden;
      @media (max-width: 640px) {
        display: none;
      }
    }
    @media (max-width: 1000px) {
      .container {
        padding: 0 10px 20px;
      }
    }
  }
`
