import css from 'styled-jsx/css'

export default css`
  .blog-outer {
    margin-top: 10px;
    padding-top: 10px;
    background-color: rgba(255, 255, 255, 0.3);
    backdrop-filter: blur(8px);
    border-radius: 8px;
    padding-bottom: 15px;
    box-shadow: 0 1px 2px #ddd;
    .title {
      font-size: 36px;
      color: #0b5d90;
      margin-top: 36px;
      margin-bottom: 4px;
      font-weight: 500;
    }
    .subtitle {
      font-size: 18px;
      color: #0b5d90;
      padding-bottom: 32px;
    }
    .btn-outer {
      margin-top: 8px;
    }
    :global(.btn) {
      color: #666;
      background-color: #eee;
      font-size: 16px;
      width: 300px;
      height: 50px;
      &:hover {
        background-color: #ddd;
      }
    }
  }

  .blog-item-outer {
    width: 96%;
    padding-left: 4.8%;
    margin-left: auto;
    margin-right: auto;
    @media (max-width: 500px) {
      padding-left: 0;
    }
    .blog-item {
      background-size: cover;
      background-position: center;
      float: left;
      margin-right: 2.1%;
      margin-bottom: 15px;
      width: calc(33.33% - 3.2%);
      padding-bottom: calc(26% - 3.4%);
      position: relative;
      @media (max-width: 500px) {
        width: 100%;
        padding-bottom: 60%;
        margin-right: 0;
      }
      &:last-child {
        margin-right: 0%;
      }
      .info {
        background-color: rgba(0, 0, 0, 0.6);
        color: #fff;
        text-align: left;
        font-size: 20px;
        font-weight: 500;
        padding: 10px 12px;
        position: absolute;
        bottom: 0;
        width: 100%;
        box-sizing: border-box;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        .count {
          font-size: 14px;
          padding: 5px 0 0;
        }
      }
    }
  }
  .title {
    font-size: 36px;
    color: #134f6c;
    margin-top: 56px;
  }
  .subtitle {
    font-size: 18px;
    color: #102c4a;
    padding-bottom: 32px;
  }
  .btn-outer {
    clear: both;
  }
  :global(.btn) {
    color: #666;
    background-color: #eee;
    font-size: 16px;
    width: 300px;
    height: 50px;
    &:hover {
      background-color: #ddd;
    }
  }
`
