import emojiRegex from 'emoji-regex'

export default function cleanStringForUrl(str) {
  return encodeURIComponent(
    str
      .replace(emojiRegex(), '')
      .replace(/[^\u4e00-\u9fa5a-zA-Z]/g, '-')
      .replace(/\%/g, '')
      .replace(/ /g, '-')
      .replace(/-+/g, '-')
      .replace('é', 'e')
      .slice(0, 20)
  )
}
