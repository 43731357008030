import css from 'styled-jsx/css'

export default css`
  @keyframes help-bubble-pulse {
    0% {
      transform: scale(1);
      opacity: 0.75;
    }
    25% {
      transform: scale(1);
      opacity: 0.75;
    }
    100% {
      transform: scale(2.5);
      opacity: 0;
    }
  }
  .header-frame {
    display: flex;
    &.loading {
      position: fixed;
      width: 100%;
      top: 0;
      background-color: #134f6c;
      .tool-bar-root {
        width: 100%;
        display: flex;
        align-items: center;
        padding-left: 16px;
        padding-right: 16px;
        @media (min-width: 600px) {
          padding-left:24px;
          padding-right: 24px;
        }
      }
    }
    :global(.tool-bar-root) {
      min-height: 56px;
      @media (min-width: 600px) {
        min-height: 56px;
      }
      @media (min-width: 781px) {
        min-height: 64px;
      }
    }
    :global(.app-bar.default) {
      background-color: #134f6c;
    }
  }
  .drawer-paper {
    width: 250px;
  }
  .tool-bar {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: visible;
    :global(.ifoodie-logo) {
      fill: #ffffff;
      width: 100px;
      height: 38px;
      &:active {
        opacity: 0.8;
      }
      @media (max-width: 780px) {
        height: 28px;
      }
    }
    .right {
      flex: none;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      :global(.sub-header) {
        width: auto;
        position: relative;
        @media (max-width: 1000px) {
          display: none;
        }
        :global(.app-bar) {
          background-color: transparent;
          box-shadow: none;
          :global(.tool-bar-root) {
            padding-right: 0;
            :global(.sub-header-list) {
              :global(.sub-header-item) {
                :global(.menu-content-outer) {
                  padding-top: 15px;
                }
                :global(.sub-header-btn) {
                  padding: 12px;
                  margin: 0;
                  border-radius: 3px;
                  &:hover {
                    margin-top: 15px;
                    background: rgba(153, 153, 153, 0.2);
                  }
                }
                :global(.sub-header-label) {
                  color: #fff;
                  font-weight: 700;
                  :global(.icon-arrow-down) {
                    margin-left: 0;
                  }
                }
              }
              :global(.sub-header-divider) {
                width: 1px;
                height: 27px;
                border-right: 1px solid #4d7288;
                &:first-child {
                  display: none;
                }
              }
            }
            :global(.sub-header-list-right) {
              display: none;
            }
          }
          :global(.icon-restaurant) {
            display: none;
          }
          :global(.icon-location-city) {
            display: none;
          }
        }
      }
    }
    .left {
      flex: none;
    }
    .center {
      flex: 1;
    }
    :global(.navigation-btn) {
      padding: 12px;
      &:hover {
        background: rgba(153, 153, 153, 0.2);
      }
      .header-app-link {
        color: #fff;
      }
    }
    :global(.navigation-btn__label) {
      color: #ffffff;
      font-weight: bold;
    }
    .avatar {
      border-radius: 50%;
      display: inline-block;
      vertical-align: middle;
      width: 44px;
      height: 44px;
      border: 1px solid white;
      background-position: center;
      background-size: cover;
      background-color: #ddd;
      cursor: pointer;
      @media (max-width: 780px) {
        width: 36px;
        height: 36px;
        border: 1px solid rgba(255, 255, 255, 0.5);
      }
    }
    .navigation-list {
      display: flex;
      flex-direction: row;
      align-items: center;
      li {
        position: relative;
        @media (max-width: 780px) {
          display: none;
          &.show-on-mobile {
            display: block;
          }
        }
        &.navigation-item {
          .avatar {
            margin-right: 10px;
          }
        }
        &.avatar-item {
          margin-left: 16px;
          margin-right: 10px;
          @media (max-width: 780px) {
            display: flex;
            margin-left: 12px;
            margin-right: 0;
          }
        }
        &.app-download-item {
          @media (max-width: 780px) {
            display: flex;
            &.hide {
              display: none;
            }
          }
        }
        &.drop-down-menu {
          @media (max-width: 780px) {
            display: flex;
          }
        }
      }
    }
    .navigation-divider {
      width: 1px;
      height: 27px;
      border-right: 1px solid #4d7288;
      @media (max-width: 780px) {
        display: none;
      }
    }
    .navigation-item-ranking-charts {
      :global(.icon-arrow-down) {
        width: 20px;
        margin-top: 1px;
        margin-left: -1px;
      }
    }
    .navigation-item-store:hover .hover-items {
      opacity: 1;
      visibility: visible;
    }
    .navigation-item-ranking-charts:hover .hover-items {
      opacity: 1;
      visibility: visible;
    }
    .avatar-item:hover .hover-items {
      opacity: 1;
      visibility: visible;
      right: 0;
      top: 46px;
      min-width: 120px;
      :global(.profile-btn) {
        padding: 12px 4px;
        width: 100%;
        color: rgba(0, 0, 0, 0.8);
      }
      :global(.profile-btn:hover) {
        background-color: #f5f5f5;
      }
      :global(.profile-icon) {
        margin-right: 8px;
        color: rgba(0, 0, 0, 0.8);
        width: 20px;
      }
    }
    .hover-items {
      position: absolute;
      background-color: #fff0;
      visibility: hidden;
      opacity: 0;
      transition-property: opacity, display;
      transition-duration: 0.1s;
      :global(.hover-items-content) {
        text-align: center;
        border-radius: 3px;
      }
      :global(.black-color) {
        color: #000000de;
      }
      :global(.weekly) {
        border-radius: 0;
      }
      :global(.monthly) {
        border-radius: 0;
      }
      ul {
        display: flex;
        flex-direction: column;
        padding-top: 10px;
        padding-bottom: 10px;
        background: #fff;
        li {
          a {
            color: #000;
            font-weight: 700;
            font-size: 0.875rem;
          }
        }
      }
    }
    .app-download-item {
      display: none;
    }
    .navigation-item-reserve {
      display: block;
    }
    .app-download-item,
    .navigation-item-reserve {
      .help-bubble {
        right: 2px;
        top: 4px;
        display: block;
        position: absolute;
        cursor: pointer;
      }
      .help-bubble:after {
        content: '';
        background-color: #fa3e3e;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        display: block;
        top: 3px;
        left: 3px;
      }
      .help-bubble-inner-dot,
      .help-bubble-outer-dot {
        display: block;
        text-align: center;
        opacity: 1;
        background-color: rgba(250, 62, 62, 0.4);
        width: 12px;
        height: 12px;
        border-radius: 50%;
        -webkit-animation: help-bubble-pulse 1.5s linear infinite;
        animation: help-bubble-pulse 1.5s linear infinite;
      }
    }
    @media (max-width: 500px) {
      .app-download-item {
        display: block;
      }
      .navigation-item-reserve {
        display: none;
      }
    }
  }
  .drop-down-menu {
    display: none;
    @media (max-width: 780px) {
      display: block;
    }
  }
`
