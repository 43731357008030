
// /assets/images/facebook@3x.png
const FB_IMAGE = 'https://lh3.googleusercontent.com/kgYgeL65iAt_X7k_COiLZhdkXlJxLMLza88fT9TCghEnpJzMKT5anofrwEELCGJhMEX5_a8PjXIfP_mECV7cil250wKgRLaRu6-YfRxmBQ=s60'

// /assets/images/food-icon.png
const FOOD_ICON = 'https://lh3.googleusercontent.com/5egqvTX37QdoHGjg9beYxupSk_zCj5ISEuuiTbHXjyCzTTAtlr7tRBXy-yb6_XpmhBdw1j4EQQumylOKnCXCaS7iqiQjutgk3KRLlCu9v7fU=s40'

// /assets/images/instagram@3x.png
const IG_ICON = 'https://lh3.googleusercontent.com/l-Wa-Vfx7DvyfQYJ4Gn7e39c8EkLm1gKt33uxSYCcFZJayBh-6nqxTcs2HSkFi3zHSza0uMKr0MuFweEhbYLNflql8ZnM4MZvXamiAhEaN4=s40'

const IFOODIE_IMAGE = 'https://lh3.googleusercontent.com/tEpSYmkyJ40d8r9gxMVz4iAA0xWND8mMrEvw2Fw13y83CDbKNSC81jSEG6KCyioZVnXrZbYaQm5vmfwW05CKcEA7LhBN88U=s600'

const EMPTY_IMAGE = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=='

export { FB_IMAGE, FOOD_ICON, IG_ICON }
export { EMPTY_IMAGE, IFOODIE_IMAGE }