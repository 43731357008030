import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styles from '@/styles/Home/BlogList.module.js'
import Button from '@mui/material/Button'
import cleanStringForUrl from '@/libs/cleanStringForUrl'
import config from '@/config'
import { List } from 'immutable'
import GAEventTracker from '@/libs/GAEventTracker'

class BlogList extends PureComponent {
  render() {
    const { blogs } = this.props
    return (
      <div className="blog-outer">
        <style jsx>{styles}</style>
        <h2 className="title">最新食記</h2>
        <div className="subtitle">每天更新最熱門文章</div>
        <div className="blog-item-outer">
          {blogs.map((blog) => {
            return (
              <a
                {...GAEventTracker.generateEventProps({
                  action: '點擊新進食記',
                  label: '區塊',
                  category: '首頁區塊點擊',
                })}
                href={`${config.ifoodieHost}/post/${blog.get(
                  'id'
                )}-${cleanStringForUrl(blog.get('title'))}`}
                target="_blank"
                key={blog.get('id')}
                className="blog-item"
                style={{ backgroundImage: `url('${blog.get('thumb')}')` }}>
                <div className="info">
                  {blog.get('title')}
                  <div className="count">
                    <span>
                      {blog.get('city')} {blog.get('area')}
                    </span>
                    <span> · </span>
                    <span>{blog.getIn(['stat', 'browseCnt'])}人瀏覽</span>
                  </div>
                </div>
              </a>
            )
          })}
        </div>
        <div className="btn-outer">
          <Button
            target="_blank"
            onClick={this._sendGAEvent}
            href="/news/台北市"
            className="btn blog-link">
            更多最新食記
          </Button>
        </div>
      </div>
    )
  }
  _sendGAEvent() {
    GAEventTracker.sendEvent({
      action: '點擊新進食記',
      label: '按鈕',
      category: '首頁區塊點擊',
    })
  }
}

BlogList.propTypes = {
  blogs: PropTypes.instanceOf(List).isRequired,
}

export default BlogList
