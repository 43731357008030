import styles from '@/styles/header/SubHeader.module.js'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import DateRangeIcon from '@mui/icons-material/DateRange'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import RestaurantRoundedIcon from '@mui/icons-material/RestaurantRounded'
import LocationCityRoundedIcon from '@mui/icons-material/LocationCityRounded'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'

import GAEventTracker from '@/libs/GAEventTracker'
import Card from '@mui/material/Card'
import cleanStringForUrl from '@/libs/cleanStringForUrl'
import { Link } from '@/routes'
import { CITY_BY_AREA } from '@/staticData/cityByArea'
import { FOOD_ICON } from '@/libs/staticImage'

const cities = CITY_BY_AREA

const categories = [
  {
    title: '美食分類',
    name: [
      '新開幕',
      '早午餐',
      '精緻高級',
      '火鍋',
      '甜點',
      '小吃',
      '約會餐廳',
      '蛋糕',
      '咖啡',
      '餐酒館/酒吧',
      '牛排',
      '燒烤',
      '居酒屋',
      '冰品飲料',
      '合菜',
      '宵夜',
      '海鮮',
      '拉麵',
      '牛肉麵',
      '壽司',
      '素食',
    ],
  },
  {
    title: '各式料理',
    name: [
      '日本料理',
      '韓式料理',
      '中式料理',
      '美式料理',
      '義式料理',
      '泰式料理',
      '港式料理',
    ],
  },
]

class SubHeader extends Component {
  constructor(props) {
    super(props)
    const { lazyRender } = props
    this.state = {
      renderHead: !lazyRender,
    }
  }
  componentDidMount() {
    this.setState({renderHead: true})
  }
  render() {
    if (!this.state.renderHead) {
      return <div className='sub-header'>
        <style jsx>{styles}</style>
        <div className="app-bar">
          <div className="tool-bar-root" style={{display: 'flex'}}>
          <ul className="sub-header-list" style={{ flex: 1 }}>
            <div className="sub-header-divider" />
            <li className="sub-header-item">
              <Button
                onClick={this._sendGAEvent('展開城市', '展開城市')}
                classes={{
                  root: 'sub-header-btn',
                  text: 'sub-header-label',
                }}>
                <LocationCityRoundedIcon className="icon-location-city" />
                城市
                <KeyboardArrowDownIcon className="icon-arrow-down" />
              </Button>
            </li>
            <div className="sub-header-divider" />
            <li className="sub-header-item">
              <Button
                onClick={this._sendGAEvent('展開美食', '展開美食')}
                classes={{
                  root: 'sub-header-btn',
                  text: 'sub-header-label',
                }}>
                <RestaurantRoundedIcon className="icon-restaurant" />
                美食
                <KeyboardArrowDownIcon className="icon-arrow-down" />
              </Button>
            </li>
            <div className="sub-header-divider" />
          </ul>
          <ul className="sub-header-list sub-header-list-right">
          <div className="sub-header-divider promote" />
          <li className="sub-header-item promote">
            <Link route="/reservation/taipei" prefetch={false}>
              <Button
                onClick={this._sendGAEvent('點擊線上訂位', '點擊線上訂位')}
                component="a"
                target="_blank"
                href="/reservation/taipei"
                classes={{
                  root: 'sub-header-btn',
                  text: 'sub-header-label promote',
                }}>
                <DateRangeIcon className="new-icon" />
                線上訂位
              </Button>
            </Link>
          </li>
          <div className="sub-header-divider" />
          <div className="sub-header-divider" />
          </ul>

          </div>
        </div>
      </div>
    }
    return (
      <div className="sub-header">
        <style jsx>{styles}</style>
        <AppBar className="app-bar" position="static">
          <Toolbar classes={{ root: 'tool-bar-root' }}>
            <ul className="sub-header-list" style={{ flex: 1 }}>
              <div className="sub-header-divider" />
              <li className="sub-header-item">
                <Button
                  onClick={this._sendGAEvent('展開城市', '展開城市')}
                  classes={{
                    root: 'sub-header-btn',
                    text: 'sub-header-label',
                  }}>
                  <LocationCityRoundedIcon className="icon-location-city" />
                  城市
                  <KeyboardArrowDownIcon className="icon-arrow-down" />
                </Button>
                <div className="menu-content-outer">
                  <Card className="menu-content menu-content-cities">
                    {cities.map((item) => {
                      return (
                        <div className="options-outer" key={item.title}>
                          <div className="title">{item.title}</div>
                          <div className="options-cities">
                            <ul>
                              {item.name.map((name) => {
                                return (
                                  <li key={name}>
                                    <Link
                                      route={`/explore/${name}/list`}
                                      prefetch={false}>
                                      <a
                                        className="btn"
                                        onClick={this._handleClickCity({
                                          name,
                                        })}>
                                        {name}
                                      </a>
                                    </Link>
                                  </li>
                                )
                              })}
                            </ul>
                          </div>
                        </div>
                      )
                    })}
                  </Card>
                </div>
              </li>
              <div className="sub-header-divider" />
              <li className="sub-header-item">
                <Button
                  onClick={this._sendGAEvent('展開美食', '展開美食')}
                  classes={{
                    root: 'sub-header-btn',
                    text: 'sub-header-label',
                  }}>
                  <RestaurantRoundedIcon className="icon-restaurant" />
                  美食
                  <KeyboardArrowDownIcon className="icon-arrow-down" />
                </Button>
                <div className="menu-content-outer">
                  <Card className="menu-content menu-content-categories">
                    {categories.map((item) => {
                      return (
                        <div className="options-outer" key={item.title}>
                          <div className="title">{item.title}</div>
                          <div className="options-categories">
                            <ul>
                              {item.name.map((name) => {
                                return (
                                  <li key={name}>
                                    <Link
                                      route={this._getCategoryLink({ name })}>
                                      <a
                                        className="btn"
                                        onClick={this._handleClickCategory({
                                          name,
                                        })}>
                                        {name}
                                      </a>
                                    </Link>
                                  </li>
                                )
                              })}
                            </ul>
                          </div>
                        </div>
                      )
                    })}
                  </Card>
                </div>
              </li>
              <div className="sub-header-divider" />
            </ul>
            <ul className="sub-header-list sub-header-list-right">
              <div className="sub-header-divider community" />
              <li className="sub-header-item community">
                <Link route="/c/list/all" prefetch={false}>
                  <Button
                    onClick={this._sendGAEvent('點擊美食專欄', '點擊美食專欄')}
                    component="a"
                    target="_blank"
                    href="/c/list/all"
                    classes={{
                      root: 'sub-header-btn',
                      text: 'sub-header-label community',
                    }}>
                    <img
                      src={FOOD_ICON}
                      className="food-icon"
                    />
                    美食專欄
                  </Button>
                </Link>
              </li>
              <div className="sub-header-divider promote" />
              <li className="sub-header-item promote">
                <Link route="/reservation/taipei" prefetch={false}>
                  <Button
                    onClick={this._sendGAEvent('點擊線上訂位', '點擊線上訂位')}
                    component="a"
                    target="_blank"
                    href="/reservation/taipei"
                    classes={{
                      root: 'sub-header-btn',
                      text: 'sub-header-label promote',
                    }}>
                    <DateRangeIcon className="new-icon" />
                    線上訂位
                  </Button>
                </Link>
              </li>
              <div className="sub-header-divider" />
              <li className="sub-header-item news">
                <Link route="/news" prefetch={false}>
                  <Button
                    onClick={this._sendGAEvent('點擊最新文章', '點擊最新文章')}
                    href="/news"
                    classes={{
                      root: 'sub-header-btn',
                      text: 'sub-header-label',
                    }}>
                    <DescriptionOutlinedIcon className="icon-restaurant" />
                    最新文章
                  </Button>
                </Link>
              </li>
              <div className="sub-header-divider" />
            </ul>
          </Toolbar>
        </AppBar>
      </div>
    )
  }
  _getCategoryLink({ name }) {
    const { location } = this.props
    if (!!location) {
      return `/explore/${location}/list/${cleanStringForUrl(name)}`
    } else {
      return `/explore/list/${cleanStringForUrl(name)}`
    }
  }
  _handleClickCity({ name }) {
    return () => {
      GAEventTracker.sendEvent({
        action: '點選SubHeader的城市',
        label: `點選${name}`,
        category: '點選SubHeader',
      })
    }
  }
  _handleClickCategory({ name }) {
    return () => {
      GAEventTracker.sendEvent({
        action: '點選SubHeader的分類與關鍵字',
        label: `點選${name}`,
        category: '點選SubHeader',
      })
    }
  }
  _sendGAEvent(action, label) {
    return () => {
      GAEventTracker.sendEvent({
        action,
        label,
        category: '點選SubHeader',
      })
    }
  }
}

SubHeader.defaultProps = {
  lazyRender: false,
}

SubHeader.propTypes = {
  location: PropTypes.string,
}

export default SubHeader
