import React, { PureComponent } from 'react'
import { setLoginModalOpenStatus } from '@/actions/auth'
import { connect } from 'react-redux'

function withLoginInfo(BaseComponent) {
  class WithLoginInfo extends PureComponent {
    render() {
      return <BaseComponent {...this.props} />
    }
  }
  return connect(mapStateToProps, {setLoginModalOpenStatus})(WithLoginInfo)
}

const mapStateToProps = (state) => ({
  currentUser: state.currentUser
})

export default withLoginInfo
