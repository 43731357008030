import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Link } from '@/routes'
import classnames from 'classnames'
import dayjs from '@/libs/dayjsConfig'
import Dotdotdot from 'react-dotdotdot'
import formatNumber from '@/libs/formatNumber'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline'
import LikeIcon from '@mui/icons-material/FavoriteBorder'
import LikeActiveIcon from '@mui/icons-material/Favorite'
import styles from '@/styles/Community/ArticleItem.module.js'
import { Map } from 'immutable'

const DEFAULT_COVER =
  'https://lh3.googleusercontent.com/DlvUFBMNmbBYUo3COEliw6Z8f8vR7_1tOsS93_LKA-Y73XzliHxFDXBviOLumhcbqOWz49sUmfmltMER4WFvn2vvhJs44rg=s600'

class ArticleItem extends PureComponent {
  constructor(props) {
    super(props)
    this._getDesktopItem = this._getDesktopItem.bind(this)
    this._getMobileItem = this._getMobileItem.bind(this)
    this._getCoverUrl = this._getCoverUrl.bind(this)
  }
  componentDidMount() {}
  render() {
    const { article, linkTarget, customClass } = this.props
    return (
      <React.Fragment>
        <style jsx>{styles}</style>
        <div className={`articleItem largeCover ${customClass}`}>
          {this._getDesktopItem()}
          {this._getMobileItem()}
        </div>
      </React.Fragment>
    )
  }
  _getCoverUrl() {
    const { article } = this.props
    if (article.get('coverUrl')) {
      return article
        .get('coverUrl')
        .replace('=s1200', '=s1200')
        .replace('=s600', '=s600')
        .replace('=s720', '=s720')
    }
    return DEFAULT_COVER
  }
  _getDesktopItem() {
    const { article, linkTarget } = this.props
    return (
      <React.Fragment>
        <style jsx>{styles}</style>
        <div className="desktopItem">
          <Link route={`/c/list/p/${article.get('postId')}`}>
            <a target="_blank" onClick={this.props.onClickArticle}>
              <div className="desktopCoverWraper">
                <img
                  src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                  className="desktopCover lazyload"
                  data-sizes="auto"
                  data-src={this._getCoverUrl()}
                />
              </div>
            </a>
          </Link>
          <div className="desktopInfo">
            <Link route={`/c/list/p/${article.get('postId')}`}>
              <a
                className="click-tracker"
                target="_blank"
                onClick={this.props.onClickArticle}
              />
            </Link>
            <div className="articleTitle">
              <Link route={`/c/list/p/${article.get('postId')}`}>
                <a
                  className="articleTitle"
                  target={linkTarget}
                  onClick={this.props.onClickArticle}>
                  <div className="text">{article.get('title')}</div>
                </a>
              </Link>
              <span className="articleCity">{article.get('city')}</span>
            </div>
            <div className="largeCoverInfo coverInfo">
              {article.get('featured') && (
                <div className="info featured">編輯精選</div>
              )}
              <div className="info postType">{article.get('postType')}</div>
              <div className="info articleDatetime" suppressHydrationWarning>
                {dayjs
                  .unix(article.get('created'))
                  .utcOffset(8)
                  .format('MMM D, YYYY')}
              </div>
            </div>
            <div className="articleInfo">
              <div className="textInfo">
                <div className="articleContent">
                  <div className="articleContentInner">
                    <Dotdotdot clamp={2}>
                      {article.get('description')}
                    </Dotdotdot>
                  </div>
                </div>
                <div className="articleStats">
                  <img
                    className="avatar"
                    src={article
                      .getIn(['user', 'profilePic'])
                      .replace('=s600', '=s40')}
                  />
                  <span className="authorName">
                    {article.getIn(['user', 'displayName'])},{' '}
                    {article.getIn(['user', 'postCnt'])}則發文
                  </span>
                  <div className="stats hide">
                    <div className="popularity">
                      <TrendingUpIcon
                        className={classnames('icon', {
                          yellow:
                            article.get('popularity') >= 500 &&
                            article.get('popularity') < 1000,
                          red: article.get('popularity') >= 1000,
                        })}
                      />
                      <span className="text">
                        {formatNumber(article.get('popularity'))}
                      </span>
                    </div>
                    <div className="likeButton">
                      {this.props.getIsLike(article) ? (
                        <LikeActiveIcon className="likeIcon" />
                      ) : (
                        <LikeIcon className="likeIcon" />
                      )}
                      <div className="likeCount">
                        {this.props.getLikeCount(article)}
                      </div>
                    </div>
                    {!!article.get('replyCnt') && (
                      <span className="replyStat">
                        <ChatBubbleOutlineIcon className="replyIcon" />
                        <span className="replyCnt">
                          {article.get('replyCnt')}
                        </span>
                      </span>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
  _getMobileItem() {
    const { article, linkTarget } = this.props
    return (
      <React.Fragment>
        <style jsx>{styles}</style>
        <Link route={`/c/list/p/${article.get('postId')}`}>
          <a
            className="click-tracker mobile"
            target={linkTarget}
            onClick={this.props.onClickArticle}
          />
        </Link>
        <div className="mobileItem">
          <Link route={`/c/list/p/${article.get('postId')}`}>
            <a onClick={this.props.onClickArticle}>
              <img
                src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
                className="articleCover lazyload"
                data-sizes="auto"
                data-src={this._getCoverUrl()}
              />
            </a>
          </Link>
          <div className="largeCoverInfo coverInfo">
            {article.get('featured') && (
              <div className="info featured">編輯精選</div>
            )}
            <div className="info postType">{article.get('postType')}</div>
            <div className="info articleDatetime" suppressHydrationWarning>
              {dayjs
                .unix(article.get('created'))
                .utcOffset(8)
                .format('MMM D, YYYY')}
            </div>
          </div>

          <div className="articleTitle">
            <Link route={`/c/list/p/${article.get('postId')}`}>
              <a className="articleTitle" onClick={this.props.onClickArticle}>
                <div className="text">{article.get('title')}</div>
              </a>
            </Link>
            <span className="articleCity">{article.get('city')}</span>
          </div>
          <div className="articleInfo">
            <div className="textInfo">
              <div className="articleContent">
                <div className="articleContentInner">
                  <Dotdotdot clamp={2}>
                    {article.get('description') || '(閱讀文章...)'}
                  </Dotdotdot>
                </div>
              </div>
              <div className="articleStats">
                <img
                  className="avatar"
                  src={article
                    .getIn(['user', 'profilePic'])
                    .replace('=s600', '=s40')}
                />
                <span className="authorName">
                  {article.getIn(['user', 'displayName'])},{' '}
                  {article.getIn(['user', 'postCnt'])}則發文
                </span>
                <div className="stats hide">
                  <div className="popularity">
                    <TrendingUpIcon
                      className={classnames('icon', {
                        yellow:
                          article.get('popularity') >= 500 &&
                          article.get('popularity') < 1000,
                        red: article.get('popularity') >= 1000,
                      })}
                    />
                    <span className="text">
                      {formatNumber(article.get('popularity'))}
                    </span>
                  </div>
                  <div className="likeButton">
                    {this.props.getIsLike(article) ? (
                      <LikeActiveIcon className="likeIcon" />
                    ) : (
                      <LikeIcon className="likeIcon" />
                    )}
                    <div className="likeCount">
                      {this.props.getLikeCount(article)}
                    </div>
                  </div>
                  {!!article.get('replyCnt') && (
                    <span className="replyStat">
                      <ChatBubbleOutlineIcon className="replyIcon" />
                      <span className="replyCnt">
                        {article.get('replyCnt')}
                      </span>
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

ArticleItem.propTypes = {
  article: PropTypes.instanceOf(Map).isRequired,
  onClickArticle: PropTypes.func.isRequired,
  getIsLike: PropTypes.func.isRequired,
  getLikeCount: PropTypes.func.isRequired,
  customClass: PropTypes.string,
  linkTarget: PropTypes.string,
}

ArticleItem.defaultProps = {
  linkTarget: '_self',
  customClass: '',
}

export default ArticleItem
