import css from 'styled-jsx/css'

export default css`
  .restaurant-item-outer {
    &.home-user-checkin {
      @media (max-width: 500px) {
        display: none;
      }
      .restaurant-item {
        margin-top: 0;
        padding: 0 12px;
        .cover {
          width: 60px;
          height: 50px;
          min-width: 60px;
        }
        .category-outer {
          display: none !important;
        }
      }
    }

    .restaurant-item {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding-top: 13px;
      position: relative;
      .cover {
        background-color: #eee;
        width: 111px;
        min-width: 100px;
        border-radius: 4px;
        height: 99px;
        object-fit: cover;
        background-position: 50%;
        background-size: cover;
        cursor: pointer;
        box-shadow: rgba(22, 34, 51, 0.2) 0px 6px 16px;
        &:hover {
          filter: brightness(95%);
        }
      }
      .info {
        flex: 1;
        padding: 4px 0 3px 17px;
        @media (max-width: 640px) {
          flex: 1;
        }
        .short {
          color: #161616;
          font-size: 14px;
          font-weight: 300;
          line-height: 20px;
          max-height: 20px;
          overflow: hidden;
          margin-top: -2px;
          margin-bottom: 7px;
          text-align: left;
        }
        .name-outer {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          text-align: left;
          .name-link {
            display: inline-grid;
            flex: 1;
            .name {
              flex: 1;
              cursor: pointer;
              font-size: 17px;
              font-weight: 500;
              font-style: normal;
              font-stretch: normal;
              line-height: 33px;
              letter-spacing: normal;
              color: #0b5d90;
              padding: 0;
              margin: 0;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              &:hover {
                opacity: 0.85;
                // text-decoration: underline;
              }
            }
          }
        }
        .ad-icon {
          color: #1d5d90;
          border: 1px solid #1d5d90;
          border-radius: 3px;
          font-size: 12px;
          padding: 1px 3px;
          word-break: keep-all;
          opacity: 0.6;
          transform: scale(0.833333);
        }
        .count {
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: center;
          margin-top: 5px;
          text-align: left;
          .rating {
          }
          .visit-cnt {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #9b9b9b;
          }
        }
        .category-outer {
          text-align: left;
          width: 100%;
          margin-top: 8px;
          padding-bottom: 8px;
          color: #4a4a4a;
          display: flex;
          flex-direction: row;
          justify-content: flex-start;
          align-items: flex-start;
          flex-wrap: wrap;
          font-size: 14px;
          @media (max-width: 700px) {
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
          }
          @media (max-width: 640px) {
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
          }
          a {
            font-size: 14px;
            color: #0b5d90;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            &:hover {
              color: #ef6e51;
            }
          }
          .avg-price-category {
            flex: 1;
            .avg-price {
              font-weight: 500;
            }
          }
          .cat-link {
            .city {
              @media (max-width: 2048px) {
                display: none;
              }
            }
            .cat {
              cursor: pointer;
            }
          }
          .distance {
            font-size: 12px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: right;
            color: #9b9b9b;
            display: flex;
            flex-direction: row;
            justify-items: flex-end;
            align-items: center;
            :global(.icon-place) {
              font-size: 12px;
            }
            @media (max-width: 700px) {
              display: none;
            }
          }
        }
      }
      :global(.top) {
        z-index: 1;
        position: absolute;
        top: 4px;
        left: -2px;
        width: 48px;
        height: 23px;
        :global(img) {
          width: 100%;
          height: 100%;
        }
      }
    }
    .click-tracker {
      z-index: 2;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: none;
      @media (max-width: 640px) {
        display: block;
        &:active {
          background-color: rgba(0, 0, 0, 0.03);
        }
      }
    }
  }
`
