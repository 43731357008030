import css from 'styled-jsx/css'

export default css`
  .autocomplete-input {
    position: relative;
    border-radius: 15px;
    background-color: white;
    input {
      border: none;
      padding: 0;
      height: 100%;
      width: 100%;
      &:focus {
        outline: none;
      }
    }
    .suggestions {
      position: absolute;
      background-color: #ffffff;
      top: 40px;
      left: 0px;
    }
  }
`
