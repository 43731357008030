import css from 'styled-jsx/css'

export default css.global`
  :global(.download-bottom-modal-overlay) {
    overflow: hidden;
    height: 100%;
    z-index: 1200;
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background-color: rgba(0, 0, 0, 0.75);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    :global(.download-modal) {
      outline: none;
      display: inline-block;
      background: transparent;
      text-align: center;
      margin-top: -30px;
      width: 100%;
    }
    .app-logo {
      width: 120px;
      height: 120px;
      padding: 15px;
    }
    .pitch-text {
      width: 100%;
      padding: 0 16px;
      margin: 0 0 8px;
      text-align: center;
      font-size: 15px;
      line-height: 30px;
      color: black;
      &.bold {
        font-weight: bold;
      }
      @media (max-width: 320px) {
        &.list {
          display: none;
        }
      }
    }
    :global(.check-icon) {
      vertical-align: middle;
      margin-right: 5px;
      color: #02bd00;
    }
    :global(.download-button) {
      padding: 6px;
      font-size: 16px;
      color: #fff;
      background: #1967a2;
      margin: 4px 0;
      width: 95%;
      :global(.download-icon) {
        margin-left: 4px;
      }
    }
    :global(.stay-button) {
      padding: 4px;
      margin: 4px 0 8px;
      width: 95%;
    }
    .download-modal-content {
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      .cover {
        margin-top: 10px;
        height: 120px;
      }
      .btn-close {
        width: 26px;
        height: 26px;
        border-radius: 13px;
        /* display: flex; */
        display: none;
        justify-content: center;
        align-items: center;
        position: absolute;
        left: -4px;
        top: -4px;
        background: #fff;
        box-shadow: 0 2px 4px 2px rgba(0, 0, 0, 0.5);
        :global(.icon-clear-outlined) {
          color: black;
          font-size: 20px;
          font-weight: 900;
        }
      }
      position: relative;
      display: inline-flex;
      background-color: #fff;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .download-title {
        padding: 6px 12px 12px;
        font-size: 20px;
        line-height: 28px;
        color: #000;
        font-style: initial;
        font-weight: bold;
        .highlight {
          color: #fb3212;
        }
      }
    }
    :global(.ReactModal__Content) {
      transition: all 180ms ease-in-out;
      opacity: 0;
    }
    :global(.ReactModal__Content--after-open) {
      opacity: 1;
    }
  }
`
