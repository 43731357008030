import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'

import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined'
import Button from '@mui/material/Button'

import styles from '@/styles/DownloadModalBottom.module.js'
import GAEventTracker from '@/libs/GAEventTracker'
import withRandomNum from '@/hocs/withRandomNum'
import getOpenLink from '@/libs/getOpenLink'

const IMAGE_LIST = [
  'https://lh3.googleusercontent.com/iWi3-MeBdjrjSI6U-tDnTmUeiorL_MLZRuvGTC-XOXz9xPZA79HkcoQl2iMtGwc-HE2_boDfsUl7-1wLH2KugiWa34Ti4ZX683fe_h5gE_cV=s300',
  'https://lh3.googleusercontent.com/PKRW2aDUA1wM0TVlKR8gH7zvXtrFXVukDb-_4W8_6TT_nSTD2cWeYPpHoioYaMb9k27SGtHsF7-lggqP2yJHRevlYdtV3MMCUy5Rhs2a6G1g=s300',
  'https://lh3.googleusercontent.com/IEi4Qs0ANhJV52p4g3e3a0Hy4sb3zyZAIICE-ax1Z2nfnss9ZYVAuJyDnEalKQIyBuN2RXoiseqTZJTYfLkDbyJ9mxlHBOcZqeuML5ZXc4Tl=s300',
]

const MODAL_CLASSNAME = 'open-modal'

class DownloadModalBottom extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpen: true,
    }
  }
  render() {
    const { randomNum } = this.props
    const downloadLink = 'https://ifoodie.tw/app'
    return (
      <Modal
        isOpen={this.state.modalOpen}
        ariaHideApp={false}
        closeTimeoutMS={50}
        className="download-modal"
        onRequestClose={this._closeModal}
        bodyOpenClassName={MODAL_CLASSNAME}
        onAfterClose={() => {
          // Buggg......
          document.body.classList.remove(MODAL_CLASSNAME);
        }}
        overlayClassName="download-bottom-modal-overlay">
        <style jsx>{styles}</style>
        <div className="download-modal-content">
          <a onClick={this._closeModal} className="btn-close">
            <ClearOutlinedIcon className="icon-clear-outlined" />
          </a>
          <a
            onClick={this._handleOpenInApp}
            target="_blank"
            href={downloadLink}>
            <img
              className="cover"
              src={IMAGE_LIST[randomNum % IMAGE_LIST.length]}
            />
            <div className="download-title">
              接著繼續尋找 <span className="highlight">美食餐廳</span> 於 App
              之中 <br /> 這樣比較快！
            </div>
          </a>
          <Button
            onClick={this._handleOpenInApp}
            className="download-button"
            target="_blank"
            href={downloadLink}>
            繼續
          </Button>
          <Button onClick={this._closeModal} className="stay-button">
            選擇使用瀏覽器
          </Button>
        </div>
      </Modal>
    )
  }
  _closeModal = () => {
    const { onRequestClose } = this.props
    this.setState({ modalOpen: false })
    // onRequestClose()
  }
  _handleOpenInApp = (e) => {
    const { randomNum, onRequestClose } = this.props
    const openLink = getOpenLink()

    e.preventDefault()
    GAEventTracker.sendEvent({
      action: '點擊APP_Pitch',
      category: `第${randomNum % IMAGE_LIST.length}張圖片`,
      label: '從DownloadModalBottom',
    })
    window.location = openLink
    setTimeout(function () {
      window.location = 'https://ifoodie.tw/app'
      onRequestClose()
    }, 1000)
  }
}

DownloadModalBottom.defaultProps = {
}

DownloadModalBottom.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
}

export default withRandomNum(DownloadModalBottom)
