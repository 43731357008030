import css from 'styled-jsx/css'

export default css`
  .footer-frame {
    display: flex;
    flex-direction: column;
    background-color: #332e2a;
    color: #fff;
    font-size: 16px;
    padding-top: 25px;
    padding-bottom: 30px;
    .container {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      margin-right: auto;
      width: 70%;
      @media (max-width: 540px) {
        flex-direction: column;
      }
      .col {
        flex: 1;
        div {
          color: grey;
          font-size: 14px;
        }
        &:not(:first-child) {
          @media (max-width: 540px) {
            margin-top: 20px;
          }
        }
        ul {
          margin-top: 8px;
        }
        li {
          line-height: 26px;
          a {
            color: #fff;
            font-weight: 600;
          }
        }
      }
    }
    .copyright {
      display: flex;
      flex-direction: row;
      margin-left: auto;
      margin-right: auto;
      width: 70%;
      margin-top: 30px;
    }
  }
`
