
import getOs from '@/libs/getOs'

export default function getOpenLink() {
  if (typeof window == 'undefined') return null

  const platform = getOs()
  let linkEl = null
  if (platform === 'ios') {
    linkEl = document.getElementById('iosUrl')
  } else if (platform === 'android') {
    linkEl = document.getElementById('androidUrl')
  }
  if (linkEl) {
    return linkEl.getAttribute('content')
  }
  return 'https://ifoodie.tw/app'

}
