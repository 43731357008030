import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styles from '@/styles/Home/UserCheckin.module.js'
import Button from '@mui/material/Button'
import { List } from 'immutable'
import { utcFormat } from '@/libs/dayjsConfig'
import cleanStringForUrl from '@/libs/cleanStringForUrl'
import GAEventTracker from '@/libs/GAEventTracker'
import RestaurantItem from '@/components/Restaurant/RestaurantItem'

class UserCheckin extends PureComponent {
  render() {
    const { userCheckinItems, isMobileOs, dotParam } = this.props
    const displaySize = isMobileOs ? 2 : 3
    return (
      <div className="checkin-outer">
        <style jsx>{styles}</style>
        <h2 className="title">用戶動態</h2>
        <div className="subtitle">快速瀏覽最新餐廳評價</div>
        <div className="checkin-item-outer">
          {userCheckinItems.take(displaySize).map((userCheckinItem) => {
            return (
              <div key={userCheckinItem.get('id')} className="checkin-item">
                <RestaurantItem
                  className="home-user-checkin"
                  restaurantItem={userCheckinItem.get('restaurant')}
                />
                <a
                  target="_blank"
                  href={this._getCanonicalUrl(
                    userCheckinItem.get('restaurant')
                  )}>
                  <div className="info">
                    <div
                      className="checkin-img"
                      style={{
                        backgroundImage: `url('${userCheckinItem.get(
                          'photo'
                        )}')`,
                      }}
                    />
                    <div className="message">
                      {userCheckinItem.get('message')}.
                    </div>
                  </div>
                  <div className="profile-container">
                    <div
                      className="profile-img"
                      style={{
                        backgroundImage: `url('${userCheckinItem.getIn([
                          'user',
                          'profilePic',
                        ])}')`,
                      }}
                    />
                    <div className="username-container">
                      <span className="username">
                        {userCheckinItem.getIn(['user', 'displayName'])}
                      </span>
                      <span className="checkin-date">
                        {utcFormat(userCheckinItem.get('updated'))}
                      </span>
                    </div>
                  </div>
                </a>
              </div>
            )
          })}
        </div>
        <div className="btn-outer">
          <Button
            onClick={this._sendGAEvent}
            target="_blank"
            href="/explore/台北市/list"
            className="btn explore-more">
            更多動態
          </Button>
        </div>
      </div>
    )
  }
  _getCanonicalUrl(restaurantInfo) {
    let name = cleanStringForUrl(restaurantInfo.get('name'))
    return `/restaurant/${restaurantInfo.get('id')}-${name}`
  }
  _sendGAEvent() {
    GAEventTracker.sendEvent({
      action: '點擊使用者動態',
      label: '按鈕',
      category: '首頁區塊點擊',
    })
  }
}

UserCheckin.propTypes = {
  userCheckinItems: PropTypes.instanceOf(List).isRequired,
  isMobileOs: PropTypes.bool,
}

export default UserCheckin
