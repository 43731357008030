import React, { Component } from 'react'
import PropTypes from 'prop-types'
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'
import styles from '@/styles/AdSense.module.js'
import { window } from '@/libs/globalUtils'

export default class AdSense extends Component {
  constructor(props) {
    super(props)
    this._getOmittedProps = this._getOmittedProps.bind(this)
    this.state = {}
  }
  componentDidMount() {
    if(window && this.ins && this.ins.offsetHeight > 0) {
      (window.adsbygoogle = window.adsbygoogle || []).push({})
    }
  }
  render() {
    const { className, style } = this.props
    const props = this._getOmittedProps()
    let styleDict = {}
    if (style) {
      styleDict = Object.assign({}, style)
    }
    if (!styleDict.width && !styleDict.minWidth) {
      // Prevent adsense slot no width crash
      styleDict.minWidth = 300
    }
    return (
      <div className='ads' style={styleDict}>
        <style jsx>{styles}</style>
        <ins ref={el => this.ins = el} className={`${className} adsbygoogle`}
          {...props} />
      </div>
    )
  }
  _getOmittedProps() {
    return omitBy({
      'data-ad-client': this.props.client,
      'data-ad-slot': this.props.slot,
      'data-ad-layout': this.props.layout,
      'data-ad-layout-key': this.props.layoutKey,
      'data-ad-format': this.props.format,
      'data-full-width-responsive': this.props.fullWidth,
      'data-matched-content-rows-num': this.props.rows,
      'data-matched-content-columns-num': this.props.columns,
      style: this.props.style
    }, isNil)
  }
}

AdSense.propTypes = {
  className: PropTypes.string,
  style: PropTypes.object,
  client: PropTypes.string.isRequired,
  slot: PropTypes.string.isRequired,
  layout: PropTypes.string,
  format: PropTypes.string,
  fullWidth: PropTypes.string,
  layoutKey: PropTypes.string,
  rows: PropTypes.number,
  columns: PropTypes.number,
}

AdSense.defaultProps = {
  className: '',
  format: 'auto',
}
