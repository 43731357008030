import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import SearchInput from '@/components/Search/SearchInput'
import {
  getKeywordSuggestions,
  clearKeywordSuggestions,
  getPlaceSuggestions,
  clearPlaceSuggestions,
  getCitySuggestions,
  getRestaurantSuggestions,
  clearRestaurantSuggestions,
} from '@/actions/search'
import { List, Map } from 'immutable'
import { Router } from '@/routes'
import { withRouter } from 'next/router'
import omitBy from 'lodash/omitBy'
import omit from 'lodash/omit'
import pick from 'lodash/pick'

class SearchInputContainer extends PureComponent {
  constructor(props) {
    super(props)
    this._getKeywordSuggestions = this._getKeywordSuggestions.bind(this)
    this._getPlaceSuggestions = this._getPlaceSuggestions.bind(this)
    this._search = this._search.bind(this)
  }
  componentDidMount() {
    // Remove getCitySuggestions, getKeywordSuggestions
  }
  render() {
    const {
      restaurants,
      keywords,
      places,
      router,
      cities,
      mapGLBound,
      clearPlaceSuggestions,
      clearKeywordSuggestions,
      classes
    } = this.props
    return (
      <SearchInput
        currentPlaceName={this._getCurrentPlaceName()}
        currentKeyword={router.query.keyword}
        restaurantsSuggestions={restaurants}
        keywordsSuggestions={keywords}
        placeSuggestions={places}
        citySuggestions={cities}
        getPlaceSuggestions={this._getPlaceSuggestions}
        getKeywordSuggestions={this._getKeywordSuggestions}
        search={this._search}
        clearPlaceSuggestions={clearPlaceSuggestions}
        clearKeywordSuggestions={clearKeywordSuggestions}
        classes={classes}
        mapGLBound={mapGLBound}
      />
    )
  }
  _getCurrentPlaceName() {
    const { router, restaurant, poi, defaultCity } = this.props
    return (
      (router.query.place === 'current' ? '目前位置' : router.query.place) ||
      `${router.query.location || ''}${router.query.sublocation || ''}` ||
      (router.query.sw && '地圖範圍') ||
      restaurant.get('name') ||
      poi.get('poi') ||
      defaultCity
    )
  }
  _getPlaceSuggestions({ input }) {
    const {
      getCitySuggestions,
      clearPlaceSuggestions,
      getPlaceSuggestions
    } = this.props
    getCitySuggestions({
      input: this._shouldListAllCity({ input }) ? '' : input
    })

    if (input) {
      getPlaceSuggestions({ input })
    } else {
      clearPlaceSuggestions()
    }
  }
  _shouldListAllCity({ input }) {
    const { router } = this.props
    return input === '目前位置' || input === router.query.location || !input
  }
  _getKeywordSuggestions({ input }) {
    const {
      getKeywordSuggestions,
      getRestaurantSuggestions,
      clearRestaurantSuggestions
    } = this.props
    getKeywordSuggestions({ input })
    if (input) {
      getRestaurantSuggestions({ input })
    } else {
      clearRestaurantSuggestions()
    }
  }
  _search({
    place,
    sw,
    ne,
    latlng,
    location,
    sublocation,
    keyword,
    keepPlace
  }) {
    const { router, restaurant, poi, defaultCity } = this.props
    const allowedQuery = !keepPlace
      ? [
          'keyword',
          'opening',
          'takeout',
          'priceLevel',
          'page',
          'sortby',
          'range'
        ]
      : [
          'keyword',
          'location',
          'sublocation',
          'place',
          'sw',
          'ne',
          'latlng',
          'opening',
          'takeout',
          'priceLevel',
          'page',
          'sortby',
          'range'
        ]

    const currentUrlQuery = pick(router.query, allowedQuery)
    let searchCity = Boolean(location) ? location : defaultCity

    const placeQuery = omitBy(
      {
        place,
        sublocation,
        location: searchCity,
        latlng: latlng ? `${latlng.get('lat')},${latlng.get('lng')}` : null,
        sw: sw ? `${sw.get('lat')},${sw.get('lng')}` : null,
        ne: ne ? `${ne.get('lat')},${ne.get('lng')}` : null
      },
      q => !q
    )
    const urlQuery = omit(Object.assign(currentUrlQuery, placeQuery), [
      'keyword',
      'page',
      currentUrlQuery.sortby === 'distance' && !currentUrlQuery.latlng
        ? 'sortby'
        : ''
    ])
    this._routeToSearch({
      keyword,
      urlQuery,
      restaurantId: keepPlace ? restaurant.get('id') : null,
      poi: keepPlace ? poi.get('poi') : null
    })
  }
  _routeToSearch({ keyword, urlQuery, restaurantId, poi }) {
    const emptyRouteStr = keyword ? '' : '-empty'

    if (urlQuery.sublocation) {
      Router.pushRoute(
        `search-sublocation${emptyRouteStr}`,
        Object.assign(
          omit(urlQuery, ['location', 'sublocation']),
          omit(
            {
              keyword,
              location: urlQuery.location,
              sublocation: urlQuery.sublocation
            },
            [keyword ? '' : 'keyword']
          )
        )
      )
    } else if (urlQuery.location) {
      Router.pushRoute(
        `search-location${emptyRouteStr}`,
        Object.assign(
          omit(urlQuery, ['location']),
          omit(
            {
              keyword,
              location: urlQuery.location
            },
            [keyword ? '' : 'keyword']
          )
        )
      )
    } else {
      Router.pushRoute(
        `search-keyword${emptyRouteStr}`,
        Object.assign(
          urlQuery,
          omitBy(
            {
              keyword,
              restaurant: restaurantId,
              poi
            },
            q => !q
          )
        )
      )
    }
  }
}

SearchInputContainer.defaultProps = {
  classes: {
    root: ''
  },
  restaurant: new Map(),
  poi: new Map()
}
SearchInputContainer.propTypes = {
  router: PropTypes.object.isRequired,
  getKeywordSuggestions: PropTypes.func.isRequired,
  getPlaceSuggestions: PropTypes.func.isRequired,
  getCitySuggestions: PropTypes.func.isRequired,
  clearPlaceSuggestions: PropTypes.func.isRequired,
  clearKeywordSuggestions: PropTypes.func.isRequired,
  clearRestaurantSuggestions: PropTypes.func.isRequired,
  getRestaurantSuggestions: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  restaurants: PropTypes.instanceOf(List).isRequired,
  keywords: PropTypes.instanceOf(List).isRequired,
  places: PropTypes.instanceOf(List).isRequired,
  cities: PropTypes.instanceOf(List).isRequired,
  classes: PropTypes.object,
  restaurant: PropTypes.instanceOf(Map).isRequired,
  poi: PropTypes.instanceOf(Map).isRequired,
  mapGLBound: PropTypes.instanceOf(Map).isRequired,
  defaultCity: PropTypes.string
}

const mapStateToProps = state => {
  return {
    isLoading: state.search.getIn(['autocomplete', 'isLoading']),
    restaurants: state.search.getIn(['autocomplete', 'restaurants']),
    keywords: state.search.getIn(['autocomplete', 'keywords']),
    places: state.search.getIn(['autocomplete', 'places']),
    cities: state.search.getIn(['autocomplete', 'cities']),
    mapGLBound: state.search.get('mapGLBound')
  }
}

export { SearchInputContainer }
export default withRouter(
  connect(
    mapStateToProps,
    {
      getKeywordSuggestions,
      clearKeywordSuggestions,
      clearPlaceSuggestions,
      clearRestaurantSuggestions,
      getPlaceSuggestions,
      getCitySuggestions,
      getRestaurantSuggestions
    }
  )(SearchInputContainer)
)
