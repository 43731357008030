import { PureComponent } from 'react'
import { connect } from 'react-redux'

function withRandommNum(WrappedComponent) {
  return connect(mapStateToProps)(
    class extends PureComponent {
      render() {
        return <WrappedComponent {...this.props} />
      }
    }
  )
}

const mapStateToProps = (state) => ({
  randomNum: state.app.get('randomNum'),
})

export default withRandommNum
