import css from 'styled-jsx/css'

export default css`
  .search-input-wrap {
    @media (max-width: 750px) {
      justify-content: flex-end;
      display: flex;
      align-items: center;
    }
    :global(.page-header-input) {
      justify-content: center;
      padding-left: 16px;
      @media (max-width: 750px) {
        display: none;
      }
    }
    :global(.search-link-btn) {
      fill: #ffffff;
      width: 24px;
      height: 24px;
      cursor: pointer;
      display: none;
      @media (max-width: 750px) {
        display: block;
      }
    }
  }
`
