import css from 'styled-jsx/css'

export default css`
  .rating-star {
    height: 20px;
    width: 53px;
    border-radius: 13px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    font-size: 13px;
    font-weight: 900;
    background: #ffffff;
    .text {
      line-height: 24px;
      padding-left: 4px;
    }
    :global(.star) {
      margin-left: 2px;
      width: 14px;
      height: 14px;
      fill: #ffffff;
    }
    &.small {
      width: 46px;
      height: 18px;
      border-radius: 9px;
      font-size: 12px;
      :global(.star) {
        margin-left: 3px;
        width: 12px;
        height: 12px;
        fill: #ffffff;
      }
    }
    &.responsive {
      @media (max-width: 780px) {
        width: 51px;
        height: 18px;
        border-radius: 9px;
        font-size: 12px;
        :global(.star) {
          margin-left: 3px;
          width: 12px;
          height: 12px;
          fill: #ffffff;
        }
      }
    }
  }
  .level-5 {
    background-color: #fb3212;
  }
  .level-4 {
    background-color: #f2694b;
  }
  .level-3 {
    background-color: #fa9542;
  }
  .level-2 {
    background-color: #f5bc3f;
  }
  .level-1 {
    background-color: #eabd85;
  }
`
