
import css from 'styled-jsx/css'

export default css`
  .sub-header {
    position: fixed;
    width: 100%;
    z-index: 5;
    @media (max-width: 640px) {
      position: relative;
      display: none;
    }
    :global(.app-bar) {
      background-color: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    }
    :global(.tool-bar-root) {
      min-height: 36px;
      height: 36px;
      width: 100%;
      max-width: 65.6em;
      margin-left: auto;
      margin-right: auto;
      padding: 0 8px;
      @media (max-width: 640px) {
        padding: 0;
      }
      .sub-header-list {
        display: flex;
        flex-direction: row;
        align-items: center;
        .community {
          @media (max-width: 640px) {
            display: none;
          }
        }
        .news {
          @media (max-width: 640px) {
            display: none;
          }
        }
        .sub-header-divider {
          width: 1px;
          height: 36px;
          border-right: 1px solid rgba(0, 0, 0, 0.12);
        }
        .sub-header-item {
          .food-icon {
            width: 16px;
            height: 16px;
            margin-right: 6px;
          }
          :global(.new-icon) {
            color: #ff6e06;
            margin-right: 4px;
            text-align: center;
            width: 18px;
            height: 18px;
          }
          cursor: pointer;
          :global(.sub-header-menu) {
            border-radius: 0;
          }
          :global(.sub-header-label) {
            font-size: 14px;
            font-weight: normal;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #4a4a4a;
          }
          :global(.icon-restaurant) {
            width: 18px;
            height: 18px;
            margin-right: 4px;
          }
          :global(.icon-location-city) {
            width: 19px;
            height: 19px;
            margin-right: 4px;
          }
          :global(.icon-arrow-down) {
            width: 19px;
            height: 19px;
            margin-left: 6px;
          }
          :global(.menu-content-outer) {
            display: none;
          }
          :global(.menu-content-cities) {
            width: 360px;
            @media (max-width: 400px) {
              max-width: 320px;
            }
          }
          :global(.menu-content-categories) {
            width: 432px;
            @media (max-width: 500px) {
              max-width: 320px;
            }
          }
          :global(.menu-content) {
            position: absolute;
            background-color: #fff;
            transition-property: opacity, display;
            transition-duration: 0.3s;
            border-radius: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            margin-right: 1px;
            :global(.btn) {
              border-radius: 0;
            }
            .options-outer {
              width: 100%;
              .title {
                padding: 6px 16px;
                background-color: #f5f5f5;
                color: #4a4a4a;
                font-size: 13px;
                font-weight: 500;
              }
              .options-cities,
              .options-categories {
                ul {
                  display: flex;
                  flex-direction: row;
                  flex-wrap: wrap;
                  background: #fff;
                  padding: 5px 16px 6px;
                  li {
                    padding: 5px 0;
                    a {
                      font-size: 14px;
                      font-weight: normal;
                      font-style: normal;
                      font-stretch: normal;
                      line-height: normal;
                      letter-spacing: normal;
                      color: #4a4a4a;
                      &:hover {
                        font-weight: 500;
                        color: #0b5d90;
                      }
                    }
                  }
                }
              }
              .options-cities {
                ul {
                  li {
                    width: 65px;
                  }
                }
              }
              .options-categories {
                ul {
                  li {
                    width: 100px;
                  }
                }
              }
            }
          }
        }
        :global(.sub-header-item:hover .menu-content-outer) {
          display: block;
        }
        :global(.sub-header-item:hover .sub-header-btn) {
          background: rgba(153, 153, 153, 0.1);
          border-radius: 0;
        }
        :global(.sub-header-item:hover .sub-header-label) {
          color: #0b5d90;
        }
        :global(.sub-header-item:hover .sub-header-label.reservation) {
          color: #ff6e06;
          &:active {
            opacity: 0.9;
          }
        }
      }
    }
  }
`