import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'next/router'
import { setLoginModalOpenStatus } from '@/actions/auth'
import { userLogin, passwordLogin } from '@/actions/currentUser'
import { Map } from 'immutable'

// import LoginModal from '@/components/LoginModal'
import dynamic from 'next/dynamic'
const LoginModal = dynamic(() => import('@/components/LoginModal'))

class LoginModalContainer extends Component {
  constructor(props) {
    super(props)
    this._onRequestClose = this._onRequestClose.bind(this)
  }
  render() {
    const {
      currentUser,
      auth,
      setLoginModalOpenStatus,
      userLogin,
      passwordLogin,
    } = this.props

    return (
      <div>
        {auth.get('isLoginModalOpen') && (
          <LoginModal
            currentUser={currentUser}
            userLogin={userLogin}
            passwordLogin={passwordLogin}
            setLoginModalOpenStatus={setLoginModalOpenStatus}
            onRequestClose={this._onRequestClose}
          />
        )}
      </div>
    )
  }
  _onRequestClose() {
    const { setLoginModalOpenStatus } = this.props
    setLoginModalOpenStatus({ isOpen: false })
  }
}

LoginModalContainer.propTypes = {
  currentUser: PropTypes.instanceOf(Map).isRequired,
  auth: PropTypes.instanceOf(Map).isRequired,
  setLoginModalOpenStatus: PropTypes.func.isRequired,
  passwordLogin: PropTypes.func.isRequired,
  userLogin: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    auth: state.auth,
  }
}

export { LoginModalContainer }
export default connect(mapStateToProps, {
  setLoginModalOpenStatus,
  userLogin,
  passwordLogin,
})(withRouter(LoginModalContainer))
