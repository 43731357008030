import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styles from '@/styles/Home/WeeklyHot.module.js'
import Button from '@mui/material/Button'
import RatingStar from '@/components/Search/RatingStar'
import cleanStringForUrl from '@/libs/cleanStringForUrl'
import config from '@/config'
import { List } from 'immutable'
import GAEventTracker from '@/libs/GAEventTracker'

class WeeklyHot extends PureComponent {
  render() {
    const { restaurants } = this.props
    return (
      <div className='weeklyhot-outer'>
        <style jsx>{styles}</style>
        <h2 className='title'>
          本週熱門
        </h2>
        <div className='subtitle'>
          愛食記精選人氣餐廳
        </div>
        <div className='restaurant-item-outer'>
          {
            restaurants.slice(0, 3).map(restaurantInfo => {
              let city = restaurantInfo.get('city')
              let cityPartEncoded = encodeURIComponent(city.substr(0, city.length - 1))
              let name = cleanStringForUrl(restaurantInfo.get('name'))
              return (
                <a
                  {...GAEventTracker.generateEventProps({ action: '點擊本週熱門', label: '區塊', category: '首頁區塊點擊'})}
                  target='_blank'
                  href={`${config.ifoodieHost}/restaurant/${restaurantInfo.get('id')}-${name}`}
                  key={restaurantInfo.get('id')}
                  className='restaurant-item'
                  style={{backgroundImage: `url('${restaurantInfo.get('coverUrl')}')`}}>
                  <div className='info'>
                    {restaurantInfo.get('name')}
                    <div className='count'>
                      {
                        restaurantInfo.get('rating') ?
                          <span>
                            <RatingStar rating={restaurantInfo.get('rating')} small={true} />
                            <span> · </span>
                          </span>
                          : null
                      }
                      <span>
                        {restaurantInfo.get('visitCnt')}則評論
                      </span>
                    </div>
                  </div>
                </a>
              )
            })
          }
        </div>
        <div className='btn-outer'>
          <Button
            target='_blank'
            onClick={this._sendGAEvent}
            href='/ranking/weekly/台北/'
            className='btn'>
            更多推薦
          </Button>
        </div>
      </div>
    )
  }
  _sendGAEvent() {
    GAEventTracker.sendEvent({
      action: '點擊本週熱門',
      label: '按鈕',
      category: '首頁區塊點擊'
    })
  }
}

WeeklyHot.propTypes = {
  restaurants: PropTypes.instanceOf(List).isRequired,
}

export default WeeklyHot
