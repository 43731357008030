import css from 'styled-jsx/css'

export default css`
  .search-outer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    .search-wrap {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    .search {
      height: 100%;
      margin: 0 auto;
      width: 100%;
      text-align: center;
      max-width: 690px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      @media (max-width: 530px) {
        justify-content: center;
      }
      .title {
        font-size: 55px;
        line-height: 55px;
        color: #fff;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        margin: 0px;
        @media (max-width: 530px) {
          font-size: 30px;
          line-height: 32px;
        }
      }
      .subtitle {
        font-size: 22px;
        line-height: 22px;
        color: #fff;
        text-align: center;
        padding: 15px 10px;
        font-weight: 500;
        @media (max-width: 530px) {
          font-size: 16px;
        }
      }
      .tag-list {
        margin-top: 20px;
        padding: 0 10px;
        max-width: 100%;
        text-align: center;
        @media (max-width: 500px) {
          font-size: 14px;
        }
        span {
          color: #fff;
          font-weight: 500;
          margin-top: 6px;
          display: inline-block;
        }
        a {
          background-color: rgba(0, 0, 0, 0.4);
          border-radius: 3px;
          color: #fff;
          padding: 3px 6px;
          margin-left: 5px;
          font-weight: 500;
          margin-top: 6px;
          display: inline-block;
          &:hover {
            background-color: rgba(0, 0, 0, 0.3);
            transition: all 180ms ease-in-out;
          }
        }
      }
      .download-app {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        .btn-ios,
        .btn-android {
          img {
            width: 160px;
            display: inline-block;
            margin: 0 8px;
            @media (max-width: 500px) {
              width: 135px;
            }
          }
        }
      }
    }
    .home-bg-outer {
      margin-top: -65px;
      min-height: 400px;
      overflow: hidden;
      position: relative;
      flex: 1;
      @media (max-width: 600px) {
        min-height: 450px;
      }
      .home-bg {
        left: -16px;
        right: -16px;
        top: -16px;
        bottom: -16px;
        position: absolute;
        background: #ccc;
        -webkit-filter: blur(8px);
        filter: blur(8px);
        background-position: 50%;
        background-size: cover;
      }
      .gradient {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        :global(canvas) {
          opacity: 0.3;
        }
        /* background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.5) 0,
          hsla(0, 0%, 100%, 0) 48%
        ); */
        background: linear-gradient(
          180deg,
          rgba(0, 0, 0, 0.5) 0,
          rgba(0, 0, 0, 0.2) 48%
        );
      }
    }
    :global(.home-search-input) {
      padding-left: 10px;
      text-align: left;
      :global(.input-group) {
        max-width: none;
        border: 0;
        height: 60px;
        padding-left: 28px;
        @media (max-width: 500px) {
          height: 48px;
          padding-left: 24px;
        }
        :global(.search-button) {
          padding-left: 32px;
          padding-right: 32px;
          height: 50px;
          @media (max-width: 500px) {
            padding-left: 20px;
            padding-right: 20px;
            height: 40px;
          }
        }
        :global(.search-icon) {
          width: 26px;
          height: 26px;
        }
      }
      :global(.autocomplete-suggestions) {
        z-index: 999;
      }
      :global(.autocomplete-input) {
        line-height: normal;
        @media (max-width: 500px) {
          font-size: 13px;
        }
        :global(.suggestions) {
          top: 62px;
          @media (max-width: 500px) {
            top: 52px;
          }
          @media (max-width: 320px) {
            width: 160px;
          }
        }
      }
    }
  }
`
