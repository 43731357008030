import css from 'styled-jsx/css'

export default css`
  @font-face {
    font-display: optional;
  }

  .content {
    margin-top: 64px;
    display: flex;
    min-height: calc(100vh - 64px);
    main {
      width: 100%;
    }
    @media (max-width: 780px) {
      margin-top: 56px;
      min-height: calc(100vh - 56px);
    }
  }
`
