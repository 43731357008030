import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from '@/styles/Home/Search.module.js'
import SearchInputContainer from '@/containers/SearchInputContainer'
import GAEventTracker from '@/libs/GAEventTracker'
import withRandomNum from '@/hocs/withRandomNum'

export const TAG_LIST = [
  '火鍋',
  '早午餐',
  '約會餐廳',
  '牛排',
  '中式料理',
  '居酒屋',
  '餐酒館',
  '小吃',
  '日式料理',
  '吃到飽',
  '甜點',
]
export const APP_LINK_IOS =
  'https://apps.apple.com/tw/app/%E6%84%9B%E9%A3%9F%E8%A8%98-%E5%8F%B0%E7%81%A3%E7%B2%BE%E9%81%B8%E9%A4%90%E5%BB%B3-x-%E7%BE%8E%E9%A3%9F%E5%84%AA%E6%83%A0/id757992884'
export const APP_LINK_ANDROID =
  'https://play.google.com/store/apps/details?id=com.djages.taipeifoodblogs&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-AC-global-none-all-co-pr-py-PartBadges-Oct1515-1'
export const BG_URLS = [
  'https://lh3.googleusercontent.com/wXvQshshpR8SkpSsp5rU2OSjyj0cVPf5iwb3qiokv18__cbTV--hr8b_hLE3RIt_T2sfJa49KC-_2At_KgC_NVhQWwwaUtyZ=s100',
  // 'https://lh3.googleusercontent.com/ugIG_QlzOzr-jWMaF0ilrgnJ5DTPh8h9MaURcDahFnpoZjhDhldy8g01d1ogTHeecoZhpJgCC0bjwDDSGNQ7dvOAXdl5NK0q=s100',
  // 'https://lh3.googleusercontent.com/s0EX9pipMW3U-R6kSYf_5zNMegExFbDq_NUGT5E36G4PyHeiAqteEJ-oRmVKrclv5toJ2sBvSSBZBl-9XBYgPyS480-nOco=s100',
  'https://lh3.ggpht.com/lKr05IWjuLaCvOONfaiXnh0IYzDl30Y85G_s7HQXfFC2jxhwm_umrndwiZSK6nIwkoRfO973OCHNNgiHNbVqzZ50WqfKJGEg=s100',
  'https://lh3.ggpht.com/XFijvNlQQhBem1wmksg9BCB3p0JZDyVhp-v_eUtu_AibF3Hk_4_L-sDLxHmmN1fgdEFZVfWK2DfKOzc99KBCh2EeoNOXcE8=s100',
]
export const ANDROID_BADGE_IMAGE_URL =
  'https://play.google.com/intl/en_us/badges/images/apps/zh-tw-play-badge.png'

class Search extends Component {
  constructor(props) {
    super(props)
    this.state = {
      bgUrl: BG_URLS[props.randomNum % 3],
    }
  }
  componentDidMount() {}
  render() {
    const { getExploreRoute, isMobileOs } = this.props
    const { bgUrl } = this.state

    return (
      <div className="search-outer">
        <style jsx>{styles}</style>
        <div className="home-bg-outer">
          <div
            className="home-bg"
            style={{ backgroundImage: `url(${bgUrl})` }}
          />
          <div id='homeBgGradient' className="gradient" />
        </div>

        <div className="search-wrap">
          <div className="search">
            <h1 className="title">想知道哪裡有美食？</h1>
            <div className="subtitle">
              從超過 50,000 家精選餐廳中，探索您不知道的熱門美食。
            </div>
            <SearchInputContainer classes={{ root: 'home-search-input' }} />
            <div className="tag-list">
              <span>搜尋 :</span>
              {TAG_LIST.map((tagName, index) => {
                let tagNameEncoded = encodeURIComponent(tagName)
                const tagGA = this._getGAProps('點擊上方餐廳分類', tagName)
                return (
                  <a
                    key={index}
                    target="_blank"
                    href={getExploreRoute(tagNameEncoded)}
                    {...tagGA}>
                    {tagName}
                  </a>
                )
              })}
            </div>
            <div className="download-app">
              <a className="btn-ios" href={APP_LINK_IOS}>
                <img src="https://lh3.googleusercontent.com/4RM-7v1wFvDue90bZzdoaudecvarfO7r7IwfgFJTM_ndmJSZdWUQl4QTX2OcVWN-QujQkfwauDKvoFbmo8_GVd9DuMpt7nY5Uue1sLsnihm3=s400" />
              </a>
              <a className="btn-android" href={APP_LINK_ANDROID}>
                <img src={ANDROID_BADGE_IMAGE_URL} />
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
  _getGAProps(action, label) {
    return GAEventTracker.generateEventProps({
      action,
      label,
      category: '首頁區塊點擊',
    })
  }
}

Search.propTypes = {
  getExploreRoute: PropTypes.func.isRequired,
  randomNum: PropTypes.number,
}

export default withRandomNum(Search)
