import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styles from '@/styles/Home/CategoryBlock.module.js'
import { List } from 'immutable'

import Container from '@mui/material/Container'
import GAEventTracker from '@/libs/GAEventTracker'

const DOUBLE_WIDTH_INDEX = [0, 6]
const MARGIN_RIGHT_ZERO_INDEX = [1, 4, 6, 9]

class CategoryBlock extends Component {
  render() {
    const { categoryBlockList, getExploreRoute } = this.props
    return (
      <Container
      >
      <div className="category-block-outer">
        <style jsx>{styles}</style>
        <h2 className="title" style={{ display: 'none' }}>
          全台美食
        </h2>
        <div className="subtitle" style={{ display: 'none' }}>
          尋找各地隱藏美食
        </div>
        <div className="category-block-item-outer">
          {categoryBlockList.map((categoryItem, index) => {
            const doubleSizeClassName = DOUBLE_WIDTH_INDEX.includes(index)
              ? 'double-size'
              : ''
            const marginZeroClassName = MARGIN_RIGHT_ZERO_INDEX.includes(index)
              ? 'margin-zero'
              : ''
            const nameEncoded = encodeURIComponent(categoryItem.get('text'))
            return (

              <a
                key={index}
                href={getExploreRoute(nameEncoded)}
                className={`category-block-item ${doubleSizeClassName} ${marginZeroClassName}`}
                target="_blank"
                onClick={this._handleSendGAEvent(categoryItem.get('text'))}>
                  <div
                  className='category-block-item-inner'
                  >
                  <div className="cover" style={{backgroundImage: `url(${categoryItem.get('thumb')})`}} />
                  <div className="mask" />
                  <div className="info">
                    <div className="name">{categoryItem.get('text')}</div>
                    <div className="count">
                      {categoryItem.get('count')}
                      <span> 家餐廳</span>
                    </div>
                  </div>
                </div>
              </a>
            )
          })}
          <div className="clearBoth" />
          <div className="img-provider">
            圖片由 <a href="https://banbi.tw/">Banbi</a>、
            <a href="http://anrtifafa.blogspot.tw/">FaFa</a>、
            <a href="https://lotuslin.com/">奇奇</a> 提供
          </div>
        </div>
      </div>
      </Container>
    )
  }
  _handleSendGAEvent(label) {
    return () => {
      GAEventTracker.sendEvent({
        label,
        action: '點擊餐種分類',
        category: '首頁區塊點擊',
      })
    }
  }
}

CategoryBlock.propTypes = {
  categoryBlockList: PropTypes.instanceOf(List).isRequired,
  getExploreRoute: PropTypes.func.isRequired,
}

export default CategoryBlock
