import css from 'styled-jsx/css'

export default css`
  .top-blogger-outer {
    background-color: rgba(255, 255, 255, 0.3);
    padding-top: 10px;
    backdrop-filter: blur(8px);
    border-radius: 8px;
    box-shadow: 0 1px 2px #ddd;
    .top-blogger {
      display: inline-flex;
      flex-direction: row;
      margin-left: auto;
      margin-right: auto;
      @media (max-width: 810px) {
        flex-direction: column;
      }
      .top-blogger-item {
        margin-right: 2.3%;
        margin-bottom: 10px;
        @media (max-width: 810px) {
          margin-right: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        iframe {
          border: 1px solid #ececec;
          width: 100%;
        }
      }
    }
    .title {
      font-size: 36px;
      color: #0b5d90;
      margin-top: 56px;
      margin-bottom: 4px;
      font-weight: 500;
    }
    .subtitle {
      font-size: 18px;
      color: #0b5d90;
      padding-bottom: 32px;
    }
    .btn-outer {
      clear: both;
      padding: 20px 0 60px;
    }
    :global(.btn) {
      color: #fff;
      background-color: #0b5d90;
      font-size: 16px;
      width: 300px;
      height: 50px;
      &:hover {
        background-color: #0b5d90;
      }
    }
  }
`
