export default function getOs() {
  if (typeof window == 'undefined') return null

  let userAgent = window.navigator.userAgent,
    platform = window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    os = null

  if (/iPad|iPhone|iPod/.test(userAgent)) {
    os = 'ios'
  } else if (/Android/.test(userAgent)) {
    os = 'android'
  } else if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'macos'
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'windows'
  } else if (!os && /Linux/.test(platform)) {
    os = 'linux'
  }
  return os
}
