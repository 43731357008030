import React, { Component } from 'react'
import styles from '@/styles/SearchBar.module.js'
import SearchInputContainer from '@/containers/SearchInputContainer'
import SearchIcon from '@mui/icons-material/Search'
import GAEventTracker from '@/libs/GAEventTracker'
import { Link } from '@/routes'

export default class SearchBar extends Component {
  constructor(props) {
    super(props)
  }
  render() {
    return (
      <div className="search-input-wrap">
        <style jsx>{styles}</style>
        <SearchInputContainer classes={{ root: 'page-header-input' }} />
        <Link route="/explore/台北市/list" prefetch={false}>
          <SearchIcon
            {...GAEventTracker.generateEventProps({
              category: '點擊Header Search',
              action: '點搜尋Icon',
              label: '點頁面頁面',
            })}
            className="search-link-btn"
          />
        </Link>
      </div>
    )
  }
}

SearchBar.propTypes = {
}

SearchBar.defaultProps = {
}
