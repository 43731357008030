import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { window } from '@/libs/globalUtils'
import config from '@/config'
import AdSense from '@/components/AdSense'
import classnames from 'classnames'
import { Waypoint } from 'react-waypoint'
import styles from '@/styles/AdExchange.module.js'

const FAILSAFE_TIMEOUT = 3000
const PRELOAD_DISTANCE = 600

export const DEFAULT_STYLE = {
  width: '100%',
  minHeight: 280,
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column'
}

export default class AdExchange extends Component {
  constructor(props) {
    super(props)
    this.intervalId = null
    this.adxDom = React.createRef()
    this._handleAdxBackFilled = this._handleAdxBackFilled.bind(this)
    this._initAdx = this._initAdx.bind(this)
    this.state = {
      adxFail: false,
      targetSlot: null,
      refreshCnt: 0,
      isLoaded: false,
      initAdserverSet: false,
    }
  }
  componentDidMount() {
    const { adsenseSlot, slot } = this.props
    if (this.adxDom) {
      this.adxDom.current.passback = () => {
        this._executePassback()
      }
    }
    if (adsenseSlot) {
      window.addEventListener('adxBackFilled', this._handleAdxBackFilled)
    }
    this._initAdx()
    this._initRefreshCheck()
  }
  componentWillUnmount() {
    const { adsenseSlot } = this.props
    if (adsenseSlot) {
      window.removeEventListener('adxBackFilled', this._handleAdxBackFilled)
    }
    if (this.intervalId) {
      clearInterval(this.intervalId)
    }
  }
  render() {
    const {
      style,
      adsenseSlot,
      skipAdsense,
      forceAdsense,
      width,
      height,
      preload,
      prebid,
      adxDomStyle,
      withTitle,
      noShadow,
    } = this.props
    const { adxFail } = this.state
    let styleDict = {
      display: 'inline-block',
    }
    if (style) {
      styleDict = Object.assign({}, style)
    }
    if (width) {
      styleDict.width = width
    }
    if (height) {
      styleDict.height = height
    }
    if (!styleDict.width && !styleDict.minWidth) {
      // Prevent adsense slot no width crash
      styleDict.minWidth = 300
    }

    let adxStyleDict = Object.assign({}, styleDict)
    if (adxFail) {
      adxStyleDict = {
        width: 1,
        height: 1,
        top: -1000,
        left: -1000,
        display: 'inline-block',
        position: 'fixed',
      }
    }

    return (
      <React.Fragment>
        <style jsx>{styles}</style>
        {((adxFail && adsenseSlot) || forceAdsense) && (
          <AdSense
            className="ads"
            skipAdsense={skipAdsense}
            style={styleDict}
            format="rectangle"
            client={config.googleAdsenseClientId}
            slot={adsenseSlot}
          />
        )}
        <Waypoint onEnter={this._handleLoad} >
        <div
          className={classnames('adx-ads', {
            withTitle,
            noShadow,
          })}
          style={adxStyleDict}>
          {!preload && (
            <div style={{ marginTop: -PRELOAD_DISTANCE, position: 'absolute' }}>
              <Waypoint onEnter={this._handleLoad} />
            </div>
          )}
          <div id={this.props.client} ref={this.adxDom} style={adxDomStyle} />
        </div>
        </Waypoint>
      </React.Fragment>
    )
  }
  _handleAdxBackFilled(e) {
    const { adsenseSlot } = this.props
    if (adsenseSlot == e.detail.adsenseSlot) {
      this._executePassback()
    }
  }
  _handleLoad = () => {
    const { googletag, adxSlotDict } = window
    const { preload, prebid, slot, client } = this.props
    const { isLoaded } = this.state
    const { initAdserver } = this
    if (isLoaded) {
      return
    }

    if (adxSlotDict[this.props.slot]) {
      let targetSlot = adxSlotDict[this.props.slot]
      if (prebid) {
        pbjs.que.push(function () {
          // console.log('🦔 Prebid', client, slot, targetSlot)
          pbjs.requestBids({
            adUnitCodes: [client],
            // bidsBackHandler: renderFunction,
            bidsBackHandler: initAdserver,
          })
        })
        // in case PBJS doesn't load
        setTimeout(function () {
          initAdserver()
        }, FAILSAFE_TIMEOUT)
      } else if (preload) {
        googletag.pubads().refresh([targetSlot])
        // console.log('🦔 AdX preload', slot, targetSlot)
      } else {
        googletag.cmd.push(function () {
          googletag.pubads().refresh([targetSlot])
        })
        // console.log('🦔 AdX', slot, targetSlot)
      }
      this.setState({ isLoaded: true })
    }
  }
  _initAdx() {
    const { targeting, client, preload, slot } = this.props
    const { googletag, adxSlotDict } = window
    let sizes = []
    if (this.props.sizes) {
      sizes = this.props.sizes
    } else {
      sizes = [parseInt(this.props.width), parseInt(this.props.height)]
    }
    let initMethod = function () {
      // Define the ad slot
      if (adxSlotDict[this.props.slot]) {
        const legacySlot = adxSlotDict[this.props.slot]
        // console.log('💣 Destroy slot', legacySlot)
        googletag.destroySlots([legacySlot])
      }
      let targetSlot = googletag.defineSlot(
        this.props.slot,
        sizes,
        this.props.client
      )
      // Prevent slots are repeatly defined
      if (targetSlot) {
        this.setState({ targetSlot })
        adxSlotDict[this.props.slot] = targetSlot
        targetSlot.addService(googletag.pubads())
        if (targeting) {
          Object.keys(targeting).forEach(function (key) {
            // console.log(`${key}: ${targeting[key]}`)
            targetSlot.setTargeting(key, targeting[key])
          })
        }
      } else {
        if (adxSlotDict[this.props.slot]) {
          targetSlot = adxSlotDict[this.props.slot]
          googletag.cmd.push(function () {
            googletag.pubads().refresh([targetSlot])
            // console.log('🦔 AdX refresh', slot)
          })
          return
        }
        this._executePassback()
        return
      }
      // Page Targeting
      googletag
        .pubads()
        .setTargeting('page', window.location.pathname.split('/')[1])
      // Start ad fetching
      googletag.pubads().enableSingleRequest()
      googletag.pubads().collapseEmptyDivs()
      googletag.enableServices()
      googletag.display(this.props.client)
      if (preload) {
        this._handleLoad()
      }
    }
    googletag.cmd.push(initMethod.bind(this))
  }
  _executePassback = () => {
    const { onAdFail, client } = this.props
    this.setState({ adxFail: true, targetSlot: null })
    // console.log('passback:', client)
    onAdFail()
  }
  _initRefreshCheck = () => {
    const { enableRefresh } = this.props
    if (enableRefresh) {
      this.intervalId = setInterval(this._checkRefresh, 30000)
    }
  }
  _checkRefresh = () => {
    const { googletag } = window
    const { enableRefresh } = this.props
    const { targetSlot, adxFail, refreshCnt } = this.state
    if (!targetSlot || !enableRefresh || adxFail || refreshCnt >= 3) {
      return
    }
    this.setState({ refreshCnt: refreshCnt + 1 })
    googletag.cmd.push(function () {
      googletag.pubads().refresh([targetSlot])
    })
  }
  // Prebid
  initAdserver = () => {
    const { adxSlotDict } = window
    const { client, slot } = this.props
    let { targetSlot, initAdserverSet } = this.state
    if (initAdserverSet) {
      // console.log('🦔 skip initAdServer', client)
      return
    }
    this.setState({ initAdserverSet: true })
    if (adxSlotDict[this.props.slot]) {
      targetSlot = adxSlotDict[this.props.slot]
    }
    if (!targetSlot) {
      // console.log('🦔❌ no targetSlot', client)
    }
    googletag.cmd.push(function () {
      pbjs.que.push(function () {
        // console.log('🦔 initAdServer', client, targetSlot)
        pbjs.setTargetingForGPTAsync([client])
        googletag.pubads().refresh([targetSlot])
      })
    })
  }
}

AdExchange.propTypes = {
  client: PropTypes.string.isRequired,
  slot: PropTypes.string.isRequired,
  enableRefresh: PropTypes.bool,
  skipAdsense: PropTypes.bool,
  preload: PropTypes.bool,
  prebid: PropTypes.bool,
  withTitle: PropTypes.bool,
  adsenseSlot: PropTypes.string,
  sizes: PropTypes.array,
  onAdFail: PropTypes.func,
  style: PropTypes.object,
  targeting: PropTypes.object,
  adxDomStyle: PropTypes.object,
  width: PropTypes.string,
  height: PropTypes.string,
}

AdExchange.defaultProps = {
  enableRefresh: false,
  forceAdsense: false,
  skipAdsense: false,
  preload: false,
  prebid: false,
  withTitle: false,
  noShadow: false,
  adxDomStyle: {},
  onAdFail: () => {},
}




