// dayjsConfig.js
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // 用於處理時區
import relativeTime from 'dayjs/plugin/relativeTime';
import 'dayjs/locale/zh-tw'; // 導入繁體中文語言包

// 擴展插件
dayjs.extend(relativeTime); // 啟用相對時間插件
dayjs.extend(utc);
dayjs.extend(timezone);

// 設定預設時區為台北時區
dayjs.tz.setDefault("Asia/Taipei");

// 設定語言為繁體中文
dayjs.locale('zh-tw');

function customRelativeTime(ts) {
  const start = dayjs().utcOffset(8).startOf('day');
  const targetTime = dayjs.unix(ts).utcOffset(8);
  const diffInDays = targetTime.diff(start, 'day');

  if (Math.abs(diffInDays) < 1) {
    return '1天前';
  } else {
    return targetTime.from(start);
  }
}

function utcFormat(ts, format='YYYY/MM/DD') {
  return  dayjs
    .unix(ts)
    .utcOffset(8)
    .format(format)
}


export { customRelativeTime, utcFormat };
export default dayjs;
