import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styles from '@/styles/Search/AutocompleteInput.module.js'
import { List } from 'immutable'
import classnames from 'classnames'
import isTouchScreen from '@/libs/isTouchScreen'
import { setTimeout } from '@/libs/globalUtils'
import isMobileScreen from '@/libs/isMobileScreen'

const README = `
  _handleBlur: 掌控桌機輸入框 onBlur 事件
`


class AutocompleteInput extends PureComponent {
  constructor(props) {
    super(props)
    this._handleFocus = this._handleFocus.bind(this)
    this._handleBlur = this._handleBlur.bind(this)
    this._handleChange = this._handleChange.bind(this)
    this._getSuggestions = this._getSuggestions.bind(this)
    this._handleKeyPress = this._handleKeyPress.bind(this)
    this._setIsComposing = this._setIsComposing.bind(this)
    this._handleHoverSuggestion = this._handleHoverSuggestion.bind(this)
    this._handleMouseLeaveSuggestions = this._handleMouseLeaveSuggestions.bind(this)
    this._handleMouseDown = this._handleMouseDown.bind(this)
    this._onSend = this._onSend.bind(this)
    this.state = {
      isComposing: false,
      isFocused: false,
      selectedIndex: -1,
      selectedSuggestion: '',
      selectedText: props.value,
      isMobileScreen: true
    }
  }
  componentDidMount() {
    this.setState({isMobileScreen: isMobileScreen({width: 640})})
    // const { debounceWait } = this.props
    // this._getSuggestions = debounce(this._getSuggestions, debounceWait)
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      selectedText: nextProps.value
    })
  }
  componentDidUpdate(prevProps) {
    const { value } = this.props
    if(!prevProps.value && value) {
      this._moveCursorToEnd()
    }
  }
  focus() {
    this._input.focus()
  }
  render() {
    const { placeholder, type, suggestions, renderSuggestion,
      classes } = this.props
    const { selectedIndex, selectedText } = this.state
    return (
      <div className={classnames('autocomplete-input', classes.root)}>
        <style jsx>{styles}</style>
        <input
          ref={el => this._input = el}
          className={classes.input}
          type={type}
          placeholder={placeholder}
          value={ selectedText }
          onCompositionStart={this._setIsComposing(true)}
          onCompositionEnd={this._setIsComposing(false)}
          onFocus={this._handleFocus}
          onBlur={this._handleBlur}
          onKeyDown={this._handleKeyPress}
          onChange={this._handleChange} />
        {
          this._shouldShowSuggestions() && (
            <div
              className={classnames('suggestions', classes.suggestions)}
              onMouseLeave={this._handleMouseLeaveSuggestions}>
              {
                suggestions.map((suggestion, index) => (
                  <div
                    style={{cursor: 'pointer'}}
                    className='suggestion'
                    key={suggestion}
                    onMouseDown={this._handleMouseDown(index)}
                    // onClick={this._handleMouseDown(index)}
                    onMouseOver={this._handleHoverSuggestion(index)}>
                    { renderSuggestion({ suggestion, isActive: selectedIndex === index, index }) }
                  </div>
                ))
              }
            </div>
          )
        }
      </div>
    )
  }
  _moveCursorToEnd() {
    setTimeout((() => {
      if (this._input==undefined) {
        return
      }
      if (typeof this._input.selectionStart == 'number') {
        this._input.selectionStart = this._input.selectionEnd = this._input.value.length
      } else if (typeof this._input.createTextRange != 'undefined') {
        this._input.focus()
        const range = this._input.createTextRange()
        range.collapse(false)
        range.select()
      }
    }).bind(this), 0)
  }
  _setIsComposing(isComposing) {
    return () => {
      this.setState({
        isComposing
      })
    }
  }
  _handleMouseLeaveSuggestions() {
    const { value, setValue } = this.props
    const { selectedText } = this.state
    if(selectedText) {
      setValue({ value: selectedText })
    }
    this.setState({
      selectedIndex: -1,
      selectedText: value
    })
  }
  _handleHoverSuggestion(index) {
    if(isTouchScreen()) {
      return
    }
    return () => {
      this.setState({
        selectedIndex: index
      })
    }
  }
  _handleKeyPress(e) {
    const { suggestions, onSelect, getSelectedText, value, setValue } = this.props
    const { selectedIndex, isComposing } = this.state
    if(isComposing) {
      return
    }
    if(e.key === 'ArrowDown') {
      const newIndex = selectedIndex + 1 === suggestions.size ? -1 : selectedIndex + 1
      onSelect({ selectedIndex: newIndex })
      this.setState({
        selectedIndex: newIndex,
        selectedText: newIndex >= 0 ? getSelectedText({ selectedIndex: newIndex }) : value
      })
    } else if(e.key === 'ArrowUp') {
      const newIndex = selectedIndex - 1 === -2 ? suggestions.size - 1  : selectedIndex - 1
      onSelect({ selectedIndex: newIndex })
      this.setState({
        selectedIndex: newIndex,
        selectedText: newIndex >= 0 ? getSelectedText({ selectedIndex: newIndex }) : value
      })
    } else if(e.key === 'Enter') {
      if(selectedIndex >= 0) {
        setValue({ value: getSelectedText({ selectedIndex }) })
        this._onSend({
          suggestion: suggestions.get(selectedIndex),
          value: getSelectedText({ selectedIndex })
        })
        this.setState({
          selectedIndex: -1
        })
      } else {
        this._onSend({
          suggestion: null,
          value
        })
      }
    }
  }
  _shouldShowSuggestions() {
    const { suggestions } = this.props
    const { isFocused } = this.state
    return isFocused && suggestions.size > 0
  }
  _handleFocus(e) {
    const { onFocus } = this.props
    const inputValue = e.target.value
    this.setState({
      isFocused: true
    })
    let suggestionInput = this.state.selectedSuggestion === inputValue ?
      '' : inputValue
    setTimeout(() => {
      this._getSuggestions({ input: suggestionInput })
      onFocus(e)
    }, 50)
  }
  _handleMouseDown(index) {
    return (e) => {
      const { suggestions, getSelectedText, setValue } = this.props
      e.preventDefault()
      e.stopPropagation()
      setValue({ value: getSelectedText({ selectedIndex: index }) })
      // console.log('mouse down')
      this._onSend({
        suggestion: suggestions.get(index),
        value: getSelectedText({ selectedIndex: index })
      })
      this.setState({
        selectedIndex: -1,
        selectedSuggestion: getSelectedText({ selectedIndex: index })
      })
    }
  }
  _handleBlur() {
    const { onBlur } = this.props
    this.setState({
      isFocused: this.state.isMobileScreen,
      selectedIndex: -1
    })
    onBlur()
  }
  _handleChange(e) {
    const { setValue, getSelectedText } = this.props
    const { selectedIndex } = this.state
    if(selectedIndex < 0 || e.target.value !== getSelectedText({ selectedIndex })) {
      setValue({ value: e.target.value })
      this.setState({
        selectedIndex: -1,
        selectedText: ''
      })
      this._getSuggestions({ input: e.target.value })
    }
  }
  _getSuggestions({ input }) {
    const { getSuggestions } = this.props
    getSuggestions({ input })
  }
  _onSend({ suggestion, value }) {
    const { onEnterPress } = this.props
    onEnterPress({ suggestion, value })
    this._input.blur()
  }
}

AutocompleteInput.defaultProps = {
  placeholder: '',
  type: 'text',
  debounceWait: 300,
  onSelect: () => null,
  onFocus: () => null,
  onBlur: () => null,
  classes: {
    root: '',
    suggestions: '',
    input: ''
  }
}

AutocompleteInput.propTypes = {
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  debounceWait: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  getSuggestions: PropTypes.func.isRequired,
  suggestions: PropTypes.instanceOf(List).isRequired,
  renderSuggestion: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  onEnterPress: PropTypes.func.isRequired,
  getSelectedText: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired
}

export default AutocompleteInput
