import css from 'styled-jsx/css'

export default css`
  .app-download {
    position: relative;
    width: 100%;
    margin-bottom: 100px;
    height: 354px;
    &.restaurant {
      margin-top: 68px;
      margin-bottom: 88px;
      @media (max-width: 640px) {
        margin-top: 20px;
        margin-bottom: 0;
      }
    }
    @media (max-width: 500px) {
      height: 354px;
      padding: 20px 0;
    }
    .bg-outer {
      position: relative;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
    }
    .bg {
      /* background-image: url('https://lh4.ggpht.com/fr6PBE6O4e7q7IbMDcO0idY9bIPO6OQMdQ6Qk7AP6_BmDfmf6jxTCR0-PxaQlFOqxmOGhj5_bMHcEI3OKsvjxv7a4ZEa9eXv=s100');
      background-size: cover;
      background-position: 50%;
      background-repeat: no-repeat;
      filter: blur(8px); */
      background: linear-gradient(
        180deg,
        #030A20 0,
        #003b5f 70%,
        #003b5f 100%,
      );
      :global(canvas) {
        opacity: 0.7;
      }
      position: absolute;
      left: 0;
      top: 0;
      left: -24px;
      right: -24px;
      top: -24px;
      bottom: -24px;
    }
    .info-outer {
      margin-left: auto;
      margin-right: auto;
      width: 90%;
      height: 100%;
      position: relative;
      text-align: center;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      .info {
        position: relative;
        text-align: center;
        padding-right: 380px;
        color: #fff;
        @media (max-width: 896px) {
          padding-right: 0;
        }
        .title {
          font-size: 60px;
          @media (max-width: 500px) {
            font-size: 40px;
          }
        }
        .subtitle {
          font-size: 22px;
          @media (max-width: 500px) {
            font-size: 20px;
          }
        }
        .download-app {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          margin-top: 20px;
          @media (max-width: 320px) {
            flex-direction: column;
          }
          .btn-ios,
          .btn-android {
            @media (max-width: 500px) {
              flex: 1;
            }
            img {
              width: 240px;
              display: inline-block;
              margin: 0 8px;
              @media (max-width: 500px) {
                width: 160px;
              }
              @media (max-width: 400px) {
                width: 100%;
                margin: 0;
              }
            }
          }
        }
      }
      .phone {
        position: absolute;
        width: 356px;
        right: 25px;
        top: -105px;
        @media (max-width: 896px) {
          display: none;
        }
      }
    }
  }
`
