import React, { PureComponent } from 'react'
import styles from '@/styles/Home/AppDownload.module.js'
// import BirdBackground from '@/components/utils/threejs/BirdBackground'

export const APP_LINK_IOS = 'https://apps.apple.com/tw/app/%E6%84%9B%E9%A3%9F%E8%A8%98-%E5%8F%B0%E7%81%A3%E7%B2%BE%E9%81%B8%E9%A4%90%E5%BB%B3-x-%E7%BE%8E%E9%A3%9F%E5%84%AA%E6%83%A0/id757992884'
export const APP_LINK_ANDROID = 'https://play.google.com/store/apps/details?id=com.djages.taipeifoodblogs&utm_source=global_co&utm_medium=prtnr&utm_content=Mar2515&utm_campaign=PartBadge&pcampaignid=MKT-AC-global-none-all-co-pr-py-PartBadges-Oct1515-1'
export const ANDROID_BADGE_IMAGE_URL = 'https://play.google.com/intl/en_us/badges/images/apps/zh-tw-play-badge.png'

class AppDownload extends PureComponent {
  render() {
    const {page} = this.props
    return (
      <div className="app-download-outer">
        <style jsx>{styles}</style>
        <div className={`app-download ${page}`}>
          <div className="bg-outer">
            <div id='appBg' className="bg" />
            {/* <BirdBackground domId='appBg' /> */}
          </div>
          <div className="info-outer">
            <div className="info">
              <div className="title">馬上體驗愛食記</div>
              <div className="subtitle">手機下載愛食記，隨時隨地收藏美食！</div>
              <div className="download-app">
                <a className="btn-ios" href={APP_LINK_IOS}>
                  <img src="https://lh3.googleusercontent.com/4RM-7v1wFvDue90bZzdoaudecvarfO7r7IwfgFJTM_ndmJSZdWUQl4QTX2OcVWN-QujQkfwauDKvoFbmo8_GVd9DuMpt7nY5Uue1sLsnihm3=s400" />
                </a>
                <a className="btn-android" href={APP_LINK_ANDROID}>
                  <img src={ANDROID_BADGE_IMAGE_URL} />
                </a>
              </div>
              <img
                className="phone"
                src="https://lh3.googleusercontent.com/GsI6bxlbgJiyRaPzZbJaV11s6RUb_q6yn_I17qqZYepmrzFK6vKmGTjKQ6R6usDN7mnl4gqM1fYPQGWY9oBdu7b_qfmuaC8sBJY93Vd2AbpD=s640-rp"
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default AppDownload
