
export const CITY_BY_AREA = [
  {
    title: '北部地區',
    name: ['台北市', '新北市', '桃園市', '基隆市', '新竹市', '新竹縣']
  },
  {
    title: '中部地區',
    name: ['台中市', '苗栗縣', '彰化縣', '南投縣', '雲林縣']
  },
  {
    title: '南部地區',
    name: ['高雄市', '台南市', '嘉義市', '嘉義縣', '屏東縣']
  },
  {
    title: '東部地區',
    name: ['宜蘭縣', '花蓮縣', '台東縣']
  }
]

// ref: ranking/specs.py
const _TAIPEI = 1
const _KAOHSIUNG = 2
const _TAINAN = 3
const _TAICHUNG = 4
const _TAOYUAN = 5
const _YILAN = 6
const _HSINCHU = 7
const _CHIAYI = 8
const _CHANGHUA = 9
const _KEELUNG = 10
const _HUALIEN = 11
const _NANTOU = 12
const _MIAOLI = 13
const _PINGTUNG = 14
const _YUNLIN = 15
const _TAITUNG = 16

export const AREA_MAP = {
  north: {
    cities: [_TAIPEI , _TAOYUAN, _HSINCHU, _KEELUNG],
    name: '北部'
  },
  west: {
    cities: [_TAICHUNG, _MIAOLI, _NANTOU, _CHANGHUA, _YUNLIN],
    name: '中部'
  },
  south: {
    cities: [_KAOHSIUNG , _TAINAN, _CHIAYI, _PINGTUNG],
    name: '南部'
  },
  east: {
    cities: [_TAITUNG, _YILAN, _HUALIEN],
    name: '不分區'
  },
}