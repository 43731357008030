import { PureComponent } from 'react'
import { connect } from 'react-redux'

function withMobileStatus(WrappedComponent) {
  return connect(mapStateToProps)(
    class extends PureComponent {
      render() {
        return <WrappedComponent {...this.props} />
      }
    }
  )
}

const mapStateToProps = state => ({
  mobileStatus: state.app.get('mobileStatus')
})

export default withMobileStatus
