import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styles from '@/styles/Search/RatingStar.module.js'
import SvgImage from '@/components/SvgImage'

class RatingStar extends PureComponent {
  render() {
    const { small, responsive, className } = this.props
    let { rating } = this.props
    if (typeof rating != 'number') {
      console.log('❌', rating, typeof rating)
    }
    rating = typeof rating == 'number' ? rating : 0

    return (
      rating ? (
        <div className={`rating-star ${this._getRatingClass()} ${small ? 'small' : ''} ${responsive ? 'responsive' : ''} ${!!className ? className : ''}`}>
          <style jsx>{styles}</style>
          <div className='text'>{ rating.toFixed(1) }</div>
          <SvgImage
            className='star'
            svgName='icon_star' />
        </div>
      ) : null
    )
  }
  _getRatingClass() {
    const { rating } = this.props
    if (rating <= 1.9) {
      return 'level-1'
    } else if (rating <= 2.9) {
      return 'level-2'
    } else if (rating < 3.5) {
      return 'level-3'
    } else if (rating < 4.4) {
      return 'level-4'
    } else if (rating <= 5) {
      return 'level-5'
    } else {
      return 'level-5'
    }
  }
}

RatingStar.defaultProps = {
  className: '',
  rating: 0,
  small: false,
  responsive: false
}

RatingStar.propTypes = {
  className: PropTypes.string.isRequired,
  rating: PropTypes.number.isRequired,
  small: PropTypes.bool.isRequired,
  responsive: PropTypes.bool.isRequired
}

export default RatingStar
