import React, { Component } from 'react'
import PropTypes from 'prop-types'
import LazyHydrate from 'react-lazy-hydration'

import styles from '@/styles/AppFrame.module.js'
import UnauthedHeader from '@/components/header/UnauthedHeader'
import LoginModalContainer from '@/containers/LoginModalContainer'
import FooterContainer from '@/containers/FooterContainer'


class AppFrame extends Component {
  render() {
    let { children, renderHeader, showFooter, contentStyle, contentClass } =
      this.props
    return (
      <div className="app-frame">
        <style jsx>{styles}</style>
        <LazyHydrate whenIdle>
        {renderHeader()}
        </LazyHydrate>
        <div className={`content ${contentClass}`} style={contentStyle}>
          <main>{children}</main>
        </div>
        <LoginModalContainer />
        {showFooter && <FooterContainer />}
      </div>
    )
  }
}

AppFrame.defaultProps = {
  renderHeader: () => <UnauthedHeader />,
  showFooter: true,
  contentStyle: {},
  contentClass: '',
}

AppFrame.propTypes = {
  contentStyle: PropTypes.object.isRequired,
  contentClass: PropTypes.string,
  children: PropTypes.node.isRequired,
  renderHeader: PropTypes.func.isRequired,
  showFooter: PropTypes.bool.isRequired,
}

export default AppFrame
