
import css from 'styled-jsx/css'

export default css`
.recent-post-outer {
  margin-top: 10px;
  padding-top: 10px;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(8px);
  border-radius: 8px;
  padding-bottom: 15px;
  box-shadow: 0 1px 2px #ddd;
  margin-top: 8px;
  .checkin-item-outer {
    text-align: left;
  }
  .post-link-outer {
    margin: 12px 60px 16px;
    @media(max-width: 800px) {
      margin: 12px 0 16px;
    }
  }
  .post-link {
    color: grey;
    font-size: 14px;
    display: block;
    margin-bottom: 12px;
    display: inline-block;
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    &:hover {
      color: #1d79bb;
    }
    @media(max-width: 800px) {
      width: 100%;
    }
  }
  .post-link-title {
    margin-bottom: 12px;
    font-weight: bold;
  }
  .title {
    font-size: 36px;
    color: #0b5d90;
    margin-top: 56px;
    margin-bottom: 4px;
    font-weight: 500;
    a {
      color: #0b5d90;
    }
  }
  .subtitle {
    font-size: 18px;
    color: #0b5d90;
    padding-bottom: 16px;
  }
  .btn-outer {
    margin-top: 12px;
    clear: both;
    text-align: center;
  }
  :global(.btn) {
    color: #666;
    background-color: #eee;
    font-size: 16px;
    width: 300px;
    height: 50px;
    &:hover {
      background-color: #ddd;
    }
    :global(.icon-arrow) {
      width: 15px;
      height: 15px;
      margin-left: 6px;
    }
  }
  &.restaurant {
    box-shadow: none;
    .post-link-outer {
      margin: 12px 0 16px;
    }
    .title {
      font-weight: 700;
      font-size: 20px;
      color: #4a4a4a;
      margin-bottom: 12px;
      margin-top: 32px;
      @media(max-width: 640px) {
        margin-top: 17px;
        font-size: 21px;
        margin-bottom: 6px;
      }
    }
    .subtitle {
      display: none;
    }
    :global(.btn) {
      width: 100%;
      max-width: 384px;
      text-align: center;
      background: white;
      color: #e35032;
      height: 44px;
      font-size: 16px;
      border: 1px solid #ef6e51b5;
      &:hover {
        background-color: rgba(0,0,0,.02);
      }
      @media(max-width: 640px) {
        max-width: auto;
        width: 87%;
        text-align: center;
        padding: 8px 0;
        margin-bottom: 10px;
      }
    }
  }

  &.post {
    box-shadow: none;
    padding: 10px;
    .post-link-outer {
      margin: 12px 0 16px;
    }
    .title {
      font-weight: 700;
      font-size: 20px;
      color: #4a4a4a;
      margin-bottom: 12px;
      margin-top: 32px;
      @media(max-width: 640px) {
        margin-top: 17px;
        font-size: 21px;
        margin-bottom: 6px;
      }
    }
    .subtitle {
      display: none;
    }
    :global(.btn) {
      width: 100%;
      text-align: center;
      background: #f6f6f6;
      color: #666;
      height: 44px;
      &:hover {
        background-color: #f6f6f6;
      }
      @media(max-width: 640px) {
        width: 87%;
        text-align: center;
        background: #0b5d90;
        color: #fff;
        padding: 8px 0;
        margin-bottom: 10px;
        font-size: 16px;
        &:hover {
          background-color: #0b5d90;
        }
      }
    }
  }

}
`