

import css from 'styled-jsx/css'

export default css`
:global(#div-gpt-ad-1600151549294-0) {
  display: inline-block;
}

.adx-ads {
  box-shadow: rgba(22, 34, 51, 0.1) 0px 6px 16px;
}

.adx-ads.noShadow {
  box-shadow: none;
}

.adx-ads.withTitle {
  margin-top: 25px;
}
.adx-ads.withTitle::before {
  /* margin-top: 25px; */
  content: '';
  /* content: '\8acb\7e7c\7e8c\5f80\4e0b\95b1\8b80';
  display: block;
  color: #777;
  font-size: 12px;
  line-height: 1;
  text-align: center; */
  /* margin-bottom: 20px; */
  @media (max-width: 640px) {
    margin-top: 0;
  }
}
`