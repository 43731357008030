export default function isTouchScreen() {
  if(typeof window == 'undefined') {
    return false
  }
  let prefixes = ' -webkit- -moz- -o- -ms- '.split(' ')
  let mq = function(query) {
    return window.matchMedia(query).matches
  }

  if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
    return true
  }

  // include the 'heartz' as a way to have a non matching MQ to help terminate the join
  // https://git.io/vznFH
  let query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('')
  return mq(query)
}